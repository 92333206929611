.lc-display-record-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0.5rem 0;
}
.lc-display-record-form .field-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
}
.lc-display-record-form .field-item .label {
  font-weight: 500;
  color: var(--gray11);
}
