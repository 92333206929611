@import "@radix-ui/colors/blackA.css";
@import "@radix-ui/colors/mauve.css";
@import "@radix-ui/colors/grass.css";
.lc-new-session-tip-container {
  display: flex;
  flex-grow: 1000;
  justify-content: center;
  overflow: auto;
}
.chatbot-user-input {
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--gray4);
}
.chatbot-user-input .input-tool-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}
.chatbot-user-input .send-button {
  padding: 2px 4px;
  cursor: pointer;
  color: #16A085;
  -webkit-tap-highlight-color: transparent;
}
.chatbot-user-input .send-button.disabled {
  cursor: not-allowed;
  opacity: 0.6;
  color: grey;
}
.chatbot-user-input .send-button svg {
  height: 20px;
  width: 20px;
}
.possible-question .instant-send {
  display: none;
}
.possible-question:hover .instant-send {
  display: flex;
}
