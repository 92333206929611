.lc-modal {
  width: 90vw;
  max-height: 88vh;
}
.lc-modal-underlay {
  position: fixed;
  z-index: 100;
  inset: 0px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.lc-modal-underlay .lc-modal {
  position: relative;
  background: var(--lc-color-bg-2);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  animation: contentShow 300ms cubic-bezier(0.16, 1, 0.3, 1);
  display: flex;
  flex-direction: column;
  outline: none;
}
.lc-modal-underlay .lc-modal .lc-modal-content {
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}
.lc-modal-underlay .lc-modal.is-large {
  height: 95vh;
  max-height: 95vh;
}
@media (min-width: 640px) {
  .lc-modal-underlay .lc-modal.is-large {
    width: 95vw;
  }
}
@media (max-width: 639px) {
  .lc-modal-underlay .lc-modal[data-main=true] {
    font-size: 16px;
    max-width: none;
    padding: 2.5rem 0 0;
    width: 100vw;
    /* top: 100%; */
    align-self: flex-end;
    height: 93vh;
    max-height: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .lc-modal-underlay .lc-modal[data-main=true] .lc-modal-content {
    height: 100%;
    margin-top: 0;
  }
}
@keyframes contentShow {
  from {
    opacity: 0;
    transform: scale(0.4);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.lc-modal-underlay .is-transparent .lc-icon-button {
  color: var(--color-8);
}
.lc-modal-underlay .is-transparent .lc-icon-button:hover:not(:disabled) {
  background-color: unset;
  color: var(--color-6);
}
