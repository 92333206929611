.bp-swiper-container {
  width: 100%;
  position: relative;
}
.bp-swiper-container .swiper-container {
  height: 100%;
}
.bp-swiper-container .bp-swiper-slide {
  width: 100%;
  height: 100%;
}
.bp-swiper-container .bp-slide-prev,
.bp-swiper-container .bp-slide-next {
  width: 0.75rem;
  z-index: 10;
  cursor: pointer;
  position: absolute;
  color: #000;
  opacity: 0.5;
}
.bp-swiper-container .bp-slide-prev::after,
.bp-swiper-container .bp-slide-next::after {
  font-size: 24px;
}
@media (min-width: 640px) {
  .bp-swiper-container .bp-slide-prev,
  .bp-swiper-container .bp-slide-next {
    height: 2rem;
  }
}
@media (max-width: 639px) {
  .bp-swiper-container .bp-slide-prev,
  .bp-swiper-container .bp-slide-next {
    height: 1.5rem;
  }
}
