.pay-page-container .pay-icon {
  margin-top: 108px;
  display: flex;
  justify-content: center;
}
.pay-page-container .pay-info {
  margin-top: 16px;
  font-size: 22px;
  text-align: center;
}
.success-pay-page-container .success-info {
  height: 152px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 8px solid #F9F9F9;
  background-color: #fff;
}
.success-pay-page-container .success-info .success-icon {
  margin: 16px auto 8px;
}
.success-pay-page-container .success-info .success-text {
  font-size: 22px;
  text-align: center;
}
.success-pay-page-container .self-info {
  min-height: 50px;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
  background-color: #fff;
}
.success-pay-page-container .self-info .self-info-item {
  max-width: 50%;
}
.success-pay-page-container .pay-feedback {
  padding: 16px;
  border-bottom: 1px solid #eee;
  background-color: #fff;
  line-height: 24px;
}
.success-pay-page-container .pay-feedback-qrcode {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.success-pay-page-container .pay-feedback-qrcode .qrcode {
  margin-top: 65px;
  margin-bottom: 8px;
}
.success-pay-page-container .pay-feedback-qrcode .text {
  font-size: 12px;
  color: #8D8D8F;
}
/* =============================================================================
   Screen size variables
   ========================================================================== */
/* =============================================================================
   Media queries for different screen sizes
   ========================================================================== */
/****   colors *******/
/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}
#nprogress .bar {
  background: #EE4B2B;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}
/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #EE4B2B, 0 0 5px #EE4B2B;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}
/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}
#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: #EE4B2B;
  border-left-color: #EE4B2B;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}
.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}
.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}
@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.about-text {
  color: red;
}
html,
body {
  margin: 0px;
  padding: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#app.bwax-page {
  height: 100%;
}
a {
  color: inherit;
  text-decoration: none;
}
