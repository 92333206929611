.slate-editor {
  display: flex;
  flex-direction: column;
  position: relative;
}
.slate-editor *::selection {
  background-color: rgba(35, 131, 226, 0.28);
}
.slate-editor .slate-edit-area {
  flex-grow: 100;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.slate-editor .slate-edit-area blockquote + blockquote {
  padding-top: 0;
}
.slate-editor .slate-edit-area blockquote.first-blockquote {
  padding-bottom: 0;
}
.slate-editor .slate-edit-area blockquote.middle-blockquote {
  padding-bottom: 0;
}
.slate-editor .slate-edit-area .editor-area-container > [contenteditable=true] {
  min-height: 100%;
}
.slate-editor .slate-edit-area .top-toolbar {
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  background-color: #fff;
}
@media (max-width: 639px) {
  .slate-editor .slate-edit-area .top-toolbar {
    display: none;
  }
}
.slate-editor .slate-edit-area .editor-area-container {
  height: 100%;
  overflow: auto;
  position: relative;
}
@media (min-width: 640px) {
  .slate-editor .slate-edit-area .editor-area-container {
    padding: 0.5rem 0.75rem 1.5rem;
  }
  .slate-editor .slate-edit-area .editor-area-container.with-brick-toolbar {
    padding: 0.5rem 1.5rem 1.5rem;
  }
}
@media (max-width: 639px) {
  .slate-editor .slate-edit-area .editor-area-container {
    padding: 0.5rem 0.75rem 4rem;
  }
}
.slate-editor .slate-edit-area .editor-area-container [data-slate-placeholder='true'] {
  top: 0;
}
.slate-editor .slate-edit-area .editor-area-container .hyperlink-input {
  box-shadow: var(--lc-shadow-xl);
}
@media (min-width: 640px) {
  .slate-editor .slate-edit-area .show-ai-placeholder {
    position: relative;
  }
  .slate-editor .slate-edit-area .show-ai-placeholder::after {
    content: var(--richtext-editor-ai-placeholder, "Press 'space' for AI");
    color: var(--gray8);
    position: absolute;
    top: 0;
  }
}
.slate-editor .slate-edit-area h1,
.slate-editor .slate-edit-area h2,
.slate-editor .slate-edit-area h3,
.slate-editor .slate-edit-area h4,
.slate-editor .slate-edit-area h5,
.slate-editor .slate-edit-area h6,
.slate-editor .slate-edit-area blockquote,
.slate-editor .slate-edit-area li {
  position: relative;
}
.slate-editor .slate-edit-area h1.show-block-placeholder::after,
.slate-editor .slate-edit-area h2.show-block-placeholder::after,
.slate-editor .slate-edit-area h3.show-block-placeholder::after,
.slate-editor .slate-edit-area h4.show-block-placeholder::after,
.slate-editor .slate-edit-area h5.show-block-placeholder::after,
.slate-editor .slate-edit-area h6.show-block-placeholder::after,
.slate-editor .slate-edit-area blockquote.show-block-placeholder::after,
.slate-editor .slate-edit-area li.show-block-placeholder::after {
  content: attr(placeholder);
  color: var(--gray7);
  position: absolute;
  top: 0;
}
.slate-editor .slate-edit-area blockquote.show-block-placeholder.single-blockquote::after,
.slate-editor .slate-edit-area blockquote.show-block-placeholder.first-blockquote::after {
  top: 16px;
}
.slate-editor .slate-edit-area blockquote.show-block-placeholder::after,
.slate-editor .slate-edit-area li.show-block-placeholder::after {
  color: var(--gray8);
}
@media (max-width: 639px) {
  .slate-editor .slate-edit-area .inline-toolbar {
    display: none;
  }
}
.slate-editor .brushing {
  cursor: crosshair;
}
.slate-editor .editor-image-block {
  margin: 0 auto;
  max-width: 100%;
  position: relative;
  cursor: default;
}
.slate-editor .editor-image-block img {
  width: 100%;
  display: block;
}
.slate-editor .editor-image-block img:hover {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3), 0 0 4px #6ca9d9;
}
.slate-editor .editor-image-block img.active {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3), 0 0 4px #6ca9d9;
}
.slate-editor .editor-image-block input {
  text-align: center;
}
.slate-editor .editor-link-input-container {
  width: 400px;
  display: flex;
}
.slate-editor .editor-link-input-container input {
  outline: none;
  border: none;
}
.slate-editor .editor-video-block-tool {
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 4px;
  right: 4px;
  display: flex;
}
.slate-editor .editor-video-block-tool button {
  opacity: 0.5;
}
.slate-editor .editor-video-block-tool button:hover {
  opacity: 1;
}
.slate-editor .editor-block-mask {
  position: absolute;
  background-color: rgba(225, 240, 255, 0.14);
  inset: 0;
}
.slate-editor .editor-image-operations-trigger {
  position: absolute;
  display: flex;
  padding: 4px;
  top: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  color: #fff;
}
.slate-editor .seperator {
  display: inline-block;
  vertical-align: middle;
  width: 1px;
  height: 15px;
}
.slate-editor .editor-audio-block:hover {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3), 0 0 4px #6ca9d9;
}
.slate-editor .editor-audio-block.active {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3), 0 0 4px #6ca9d9;
}
.slate-editor .richtext-highlight-text {
  background-color: rgba(35, 131, 226, 0.28);
  box-shadow: 0 0 0 2px rgba(35, 131, 226, 0.28);
  color: inherit;
}
.slate-editor .hyperlink-target-blank-button {
  font-size: 11px!important;
  opacity: 0.7;
  white-space: pre;
}
@media (min-width: 640px) {
  .slate-editor .hyperlink-target-blank-button {
    background-color: #fff;
  }
}
.slate-editor .hyperlink-target-blank-button.active {
  opacity: 1;
}
.slate-editor a {
  color: var(--blue10);
}
.mobile-toolbar-container {
  position: fixed;
  width: 100%;
  background-color: #fff;
  display: flex;
  overflow-x: scroll;
  left: 0;
  align-items: center;
}
@media (min-width: 640px) {
  .mobile-toolbar-container {
    display: none;
  }
}
.mobile-toolbar-container.ai-input {
  padding: 4px;
}
.mobile-toolbar-container .editor-button {
  height: 40px;
}
.editor-mobile-upward-menu {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 100%;
  left: 0;
  transition-duration: 240ms;
  background-color: #fff;
  z-index: 1;
}
@media (min-width: 640px) {
  .editor-mobile-upward-menu {
    display: none;
  }
}
.editor-mobile-upward-menu.show {
  top: 0px;
  background-color: var(--gray2);
}
.editor-mobile-upward-menu.show .header {
  padding: 8px 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 1rem;
  background-color: #fff;
}
.editor-mobile-upward-menu.show .items-container {
  height: calc(100% - 104px);
  overflow-y: scroll;
  background-color: #fff;
}
.editor-mobile-upward-menu.show .items-container .upward-menu-item {
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 10px 12px;
  border-top: 1px solid rgba(55, 53, 47, 0.08);
}
.editor-mobile-upward-menu.show .items-container .upward-menu-item:last-child {
  border-bottom: 1px solid rgba(55, 53, 47, 0.08);
}
.editor-mobile-upward-menu.show .items-container .upward-menu-item .icon {
  width: 1rem;
  margin-right: 0.5rem;
  font-size: 15px;
  opacity: 0.7;
  display: flex;
  align-items: center;
}
.editor-mobile-link-input-modal .editor-link-input-container {
  width: 100%;
  display: flex;
  margin: 0 auto;
  gap: 8px;
}
.editor-mobile-link-input-modal .editor-link-input-container > button:first-of-type {
  display: none;
}
.editor-mobile-link-input-modal .editor-link-input-container .hyperlink-target-blank-button {
  width: 100px;
  font-size: 14px;
}
