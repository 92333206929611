.lc-search-items {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.lc-search-items .selectable-item {
  display: flex;
  align-items: start;
  gap: 1rem;
  padding: 0.5rem 0;
  cursor: pointer;
}
.lc-search-items .item-line {
  display: flex;
  align-items: start;
}
.lc-search-items .item-line i {
  margin-right: 0.25rem;
  color: var(--gray11);
  font-size: 16px;
  margin-top: -1px;
}
.lc-search-items .item-line .item-title {
  margin-top: -2px;
}
@media (max-width: 639px) {
  .lc-search-items .item-line i {
    margin-right: 0.25rem;
    color: var(--gray11);
    font-size: 18px;
  }
  .lc-search-items .item-line .item-title {
    margin-top: -4px;
  }
}
.lc-search-items .item-line svg {
  margin-right: 0.25rem;
  margin-top: 1px;
}
@media (max-width: 639px) {
  .lc-search-items .item-line svg {
    margin-top: 0px;
  }
}
