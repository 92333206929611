.lc-upload-knowledge-document > :not(:last-child) {
  margin-bottom: 0.5rem;
}
.lc-upload-knowledge-document .document-summary {
  display: flex;
  margin-top: 1.5rem;
}
.lc-upload-knowledge-document .document-summary > i {
  margin-right: 0.5rem;
  margin-top: 3px;
}
.lc-upload-knowledge-document .error-msg {
  color: var(--red11);
  font-size: small;
}
.lc-upload-knowledge-document .action-group {
  display: flex;
  justify-content: end;
  padding: 1rem 0 0;
}
.lc-upload-knowledge-document .action-group > :not(:last-child) {
  margin-right: 0.5rem;
}
.lc-upload-knowledge-document .action-group > button {
  padding: 0 1.5rem;
}
