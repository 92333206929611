.section-container {
  width: 100%;
}
.section-container .section-content {
  width: 100%;
}
.section-container .section-toggle-show-more {
  color: #666;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  cursor: pointer;
}
