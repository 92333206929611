.lc-history-message-container {
  display: flex;
  position: relative;
  flex-direction: column;
  overflow-x: hidden;
  padding: 0 1rem 0 0.75rem;
}
@media (min-width: 640px) {
  .lc-history-message-container {
    padding: 0 1.25rem 0 1rem;
  }
}
.lc-history-message-container .box-shadow-div {
  width: 100%;
  position: absolute;
  z-index: 1;
}
.lc-history-message-container .box-shadow-div.top {
  top: 0;
}
.lc-history-message-container .box-shadow-div.bottom {
  bottom: 0;
}
.lc-history-message-container .message {
  padding: 0.75rem 0;
  display: flex;
}
.lc-history-message-container .message:first-child {
  margin-top: 1rem;
}
.lc-history-message-container .message:last-child {
  margin-bottom: 1.5rem;
}
.lc-history-message-container .message .message-content-box {
  flex-basis: 0%;
  min-width: 0;
  flex-grow: 1;
}
.lc-history-message-container .message .message-content-box > button {
  margin: 0 0.5rem;
}
.lc-history-message-container .message .message-content-box > :not(:last-child) {
  margin-bottom: 0.25rem;
}
.lc-history-message-container .message .message-content-box .persona-name {
  margin: -1px 0.5rem 0;
  color: var(--gray10);
}
.lc-history-message-container .message .message-content-box:hover .message-tool-bar {
  opacity: 1;
}
.lc-history-message-container .message .message-content-box .message-tool-bar {
  opacity: 0;
  color: var(--gray10);
  transition: all 160ms ease-out;
}
.lc-history-message-container .message .message-content {
  padding: 10px 16px 10px 12px;
  margin: 0 0.5rem;
  border-radius: 4px;
  white-space: pre-wrap;
  word-break: break-word;
  flex-basis: 0%;
  min-width: 0;
  flex-grow: 1;
  max-width: fit-content;
}
.lc-history-message-container .message .message-content .responding-with-text span {
  margin-left: 0.25rem;
}
.lc-history-message-container .message .message-content .responding-steps {
  display: flex;
  flex-direction: column;
  opacity: 0.7;
}
.lc-history-message-container .message .message-content .responding-steps > div {
  display: flex;
  gap: 0.25rem;
  align-items: center;
}
.lc-history-message-container .message .message-content .responding-steps > div:not(:last-child) {
  margin-bottom: 0.125rem;
}
.lc-history-message-container .message .message-content .responding-steps > div[data-status="Done"] i {
  color: var(--grass9);
}
.lc-history-message-container .message .message-content .responding {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lc-history-message-container .message .message-content .rich-text.rich-text-v2 > p:not(:last-child),
.lc-history-message-container .message .message-content .rich-text.rich-text-v2 > div:not(:last-child) {
  margin-bottom: 16px;
}
.lc-history-message-container .message .message-content .rich-text.rich-text-v2 > figure,
.lc-history-message-container .message .message-content .rich-text.rich-text-v2 > blockquote,
.lc-history-message-container .message .message-content .rich-text.rich-text-v2 > pre,
.lc-history-message-container .message .message-content .rich-text.rich-text-v2 > .table-wrapper,
.lc-history-message-container .message .message-content .rich-text.rich-text-v2 > code {
  margin: 18px 0;
}
.lc-history-message-container .message .message-content .rich-text.rich-text-v2 > figure:last-child,
.lc-history-message-container .message .message-content .rich-text.rich-text-v2 > blockquote:last-child,
.lc-history-message-container .message .message-content .rich-text.rich-text-v2 > pre:last-child,
.lc-history-message-container .message .message-content .rich-text.rich-text-v2 > .table-wrapper:last-child,
.lc-history-message-container .message .message-content .rich-text.rich-text-v2 > code:last-child {
  margin-bottom: 10px;
}
.lc-history-message-container .message .message-content .rich-text.rich-text-v2 > ul,
.lc-history-message-container .message .message-content .rich-text.rich-text-v2 > ol {
  margin-top: 18px;
  margin-bottom: 18px;
}
.lc-history-message-container .message .message-content .rich-text.rich-text-v2 > ul:last-child,
.lc-history-message-container .message .message-content .rich-text.rich-text-v2 > ol:last-child {
  margin-bottom: 12px;
}
.lc-history-message-container .message .message-content .rich-text.rich-text-v2 li:not(:last-child) {
  margin-bottom: 10px;
}
.lc-history-message-container .message .message-content .rich-text.rich-text-v2 li > p:not(:last-child) {
  margin-bottom: 10px;
}
.lc-history-message-container .message .message-avatar-placeholder {
  width: 28px;
  min-width: 28px;
}
.lc-history-message-container .message.response .message-content {
  background-color: var(--slate2);
}
.lc-history-message-container .message.response .message-content.message-content-enhanced {
  background-color: var(--violet3);
}
.lc-history-message-container .message.response .message-content .action-bar {
  right: -32px;
}
.lc-history-message-container .message.userInput {
  justify-content: flex-end;
}
.lc-history-message-container .message.userInput .message-content {
  background-color: var(--blue3);
}
.lc-history-message-container .message.userInput .action-bar {
  left: -32px;
}
.lc-history-message-container .message:hover .message-content > .action-bar {
  display: flex;
}
.lc-history-message-container .message .message-content {
  position: relative;
}
.lc-history-message-container .message .message-content .action-bar {
  position: absolute;
  bottom: 16px;
  display: none;
  flex-direction: column;
}
.lc-history-message-container .message-divide {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 24px 0;
}
.lc-history-message-container .message-divide .message-divide-content {
  max-width: 50%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #c8cacc;
}
.lc-history-message-container .message-divide .message-divide-content > div {
  width: 32%;
  height: 1px;
  background-color: #DCDEE0;
  margin: 0 8px;
}
