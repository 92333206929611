.lc-popover {
  border-radius: 4px;
  padding: 20px;
  width: 260px;
  background-color: var(--lc-color-bg-2);
  box-shadow: hsla(206, 22%, 7%, 0.35) 0px 10px 38px -10px, hsla(206, 22%, 7%, 0.2) 0px 10px 20px -15px;
  animation-duration: 160ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}
.lc-popover[data-state='open'][data-side='top'] {
  animation-name: slideDownAndFade;
}
.lc-popover[data-state='open'][data-side='right'] {
  animation-name: slideLeftAndFade;
}
.lc-popover[data-state='open'][data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.lc-popover[data-state='open'][data-side='left'] {
  animation-name: slideRightAndFade;
}
.lc-popover .PopoverArrow {
  fill: var(--lc-color-bg-2);
}
.lc-popover .PopoverClose {
  all: unset;
  font-family: inherit;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--violet11);
  position: absolute;
  top: 5px;
  right: 5px;
}
.lc-popover .PopoverClose:hover {
  background-color: var(--violet4);
}
.lc-popover .PopoverClose:active {
  background-color: var(--violet5);
}
.lc-popover .IconButton {
  font-family: inherit;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--violet11);
  background-color: white;
  box-shadow: 0 2px 10px var(--blackA7);
}
.lc-popover .IconButton:hover {
  background-color: var(--violet3);
}
@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
