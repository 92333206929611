.ai-operation-menu-box {
  display: flex;
  border-radius: 4px;
  position: relative;
  user-select: none;
}
.ai-operation-menu-box.hidden {
  display: none;
}
@media (min-width: 640px) {
  .ai-operation-menu-box {
    width: fit-content;
    margin-top: 4px;
  }
}
@media (max-width: 639px) {
  .ai-operation-menu-box {
    width: 100%;
    margin-bottom: 4px;
  }
}
.ai-operation-menu-box .ai-operation-menu {
  height: 100%;
  max-height: min(40vh, 320px);
  overflow-y: scroll;
  box-shadow: var(--richtext-tool-box-shadow);
  background-color: var(--lc-color-bg-3);
  border-radius: 4px;
}
@media (min-width: 640px) {
  .ai-operation-menu-box .ai-operation-menu {
    width: 320px;
  }
}
@media (max-width: 639px) {
  .ai-operation-menu-box .ai-operation-menu {
    width: 100%;
    max-height: 100px;
  }
}
.ai-operation-menu-box .ai-operation-menu .operations-group {
  padding-top: 6px;
  padding-bottom: 6px;
}
.ai-operation-menu-box .ai-operation-menu .operations-group:not(:first-child) {
  box-shadow: rgba(55, 53, 47, 0.09) 0px -1px 0px;
}
.ai-operation-menu-box .ai-operation-menu .operations-group p {
  display: flex;
  padding: 0 14px;
  margin: 6px 3px 8px;
  color: var(--gray10);
  font-weight: 500;
  line-height: 1.2;
  font-size: 12px;
}
@media (max-width: 639px) {
  .ai-operation-menu-box .ai-operation-menu .operations-group .ai-operations-container > p {
    display: none;
  }
}
.ai-operation-menu-box .operation {
  cursor: pointer;
  width: calc(100% - 8px);
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 3px;
  padding: 4px 14px;
  min-height: 28px;
  display: flex;
  align-items: center;
}
.ai-operation-menu-box .operation[data-disabled=true] {
  cursor: not-allowed;
  opacity: 0.4;
}
.ai-operation-menu-box .operation[data-disabled=false]:hover {
  background: var(--gray3);
}
.ai-operation-menu-box .operation span {
  margin-left: 10px;
  width: 100%;
}
.ai-operation-menu-box .operation > svg:last-child {
  color: rgba(55, 53, 47, 0.65);
  font-size: 16px;
}
.ai-operation-menu-box .ai-operation-sub-menu {
  width: 220px;
  box-shadow: var(--richtext-tool-box-shadow);
  background-color: var(--lc-color-bg-3);
  padding: 6px 0;
  position: absolute;
  left: 320px;
}
@media (max-width: 639px) {
  .ai-operation-menu-box .ai-operation-sub-menu {
    display: none;
  }
}
.ai-operation-menu-box .sub-item {
  user-select: none;
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  align-items: center;
  line-height: 1.2;
  min-height: 28px;
}
.ai-operation-menu-box .sub-item[data-disabled=false]:hover {
  background: var(--gray3);
}
.ai-operation-menu-box .sub-item[data-disabled=true] {
  cursor: not-allowed;
  opacity: 0.4;
}
@media (min-width: 640px) {
  .ai-operation-menu-box .sub-item {
    font-size: 14px;
    padding: 4px 10px;
    margin: 0 4px;
    width: calc(100% - 8px);
  }
}
.dark-theme .ai-operation-menu-box .sub-item[data-disabled=false]:hover {
  background: var(--gray4);
}
.dark-theme .ai-operation-menu-box .operation[data-disabled=false]:hover {
  background: var(--gray4);
}
