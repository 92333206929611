.bw-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 639px) {
}
@media (min-width: 640px) {
  .bw-modal.compact .modal-container {
    width: unset;
  }
}
.bw-modal .modal-container {
  background-color: white;
  max-width: 40rem;
  width: calc(100% - 2rem);
  min-height: 12rem;
  /* align-self: stretch; */
  /* justify-self: center; */
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 1001;
  overflow: hidden;
  position: relative;
}
@media (min-width: 640px) {
  .bw-modal .modal-container {
    margin-top: 5vh;
    margin-bottom: 10vh;
    border-radius: 8px;
  }
}
@media (max-width: 639px) {
  .bw-modal .modal-container {
    margin-top: 5vh;
    width: 100%;
    max-height: 94vh;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
  }
}
.bw-modal .modal-container .modal-header-no-style {
  width: 100%;
}
.bw-modal .modal-container .modal-header {
  min-height: 2.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.bw-modal .modal-container .modal-header .modal-head-left-icon {
  height: 100%;
  width: 2.75rem;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  opacity: 0.6;
}
.bw-modal .modal-container .modal-header .modal-icon-button {
  cursor: pointer;
}
.bw-modal .modal-container .modal-header .modal-icon-button:active {
  background-color: #F8F9F9;
}
.bw-modal .modal-container .modal-body {
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.bw-modal .modal-container .modal-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bw-modal.visible {
  visibility: visible;
}
.bw-modal.hidden {
  visibility: hidden;
}
.bw-modal .modal-mask {
  background-color: rgba(39, 44, 54, 0.4);
}
.bw-modal .modal-mask,
.bw-modal .modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
