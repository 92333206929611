.lc-dropdown-menu {
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0.5rem;
  margin: 3px 0;
  overflow: auto;
  outline: none;
  list-style: none;
}
.lc-dropdown-menu > li {
  border-radius: 3px;
  padding: 7px 11px;
  min-width: 10rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.lc-dropdown-menu > li[data-focused=true] {
  outline: none;
  background-color: var(--color-2);
  color: var(--color-label);
}
.lc-dropdown-menu > li:hover {
  outline: none;
  background-color: var(--color-3);
  color: var(--color-label);
}
.lc-dropdown-menu > li[data-disabled=true] {
  opacity: 0.3;
  cursor: not-allowed;
}
