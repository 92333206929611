.lc-layout-inbot-drawer .side-menu,
.lc-layout-inbot .side-menu,
.lc-layout-inbot-drawer .side-menu-in-drawer,
.lc-layout-inbot .side-menu-in-drawer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0.625rem 0;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  background: #fbfbfa;
  transition: box-shadow 300ms ease 0s;
  width: 160px;
}
.lc-layout-inbot-drawer .side-menu .bottom-group,
.lc-layout-inbot .side-menu .bottom-group,
.lc-layout-inbot-drawer .side-menu-in-drawer .bottom-group,
.lc-layout-inbot .side-menu-in-drawer .bottom-group {
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0;
}
.lc-layout-inbot-drawer .side-menu[data-collapsed=true],
.lc-layout-inbot .side-menu[data-collapsed=true],
.lc-layout-inbot-drawer .side-menu-in-drawer[data-collapsed=true],
.lc-layout-inbot .side-menu-in-drawer[data-collapsed=true] {
  padding: 0.5rem 0 0.5rem 3px;
}
.lc-layout-inbot-drawer .side-menu[data-collapsed=true] .title-and-avatar,
.lc-layout-inbot .side-menu[data-collapsed=true] .title-and-avatar,
.lc-layout-inbot-drawer .side-menu-in-drawer[data-collapsed=true] .title-and-avatar,
.lc-layout-inbot .side-menu-in-drawer[data-collapsed=true] .title-and-avatar {
  justify-content: center;
  padding: 0.5rem 0.5rem 0.75rem;
}
.lc-layout-inbot-drawer .side-menu[data-collapsed=true] .feature-nav .feature-nav-item,
.lc-layout-inbot .side-menu[data-collapsed=true] .feature-nav .feature-nav-item,
.lc-layout-inbot-drawer .side-menu-in-drawer[data-collapsed=true] .feature-nav .feature-nav-item,
.lc-layout-inbot .side-menu-in-drawer[data-collapsed=true] .feature-nav .feature-nav-item {
  justify-content: center;
  padding: 0.75rem;
}
.lc-layout-inbot-drawer .side-menu .title-and-avatar,
.lc-layout-inbot .side-menu .title-and-avatar,
.lc-layout-inbot-drawer .side-menu-in-drawer .title-and-avatar,
.lc-layout-inbot .side-menu-in-drawer .title-and-avatar {
  padding: 0.5rem 0.75rem 0.5rem 1rem;
  display: flex;
  align-items: flex-start;
}
.lc-layout-inbot-drawer .side-menu .title-and-avatar .title-box,
.lc-layout-inbot .side-menu .title-and-avatar .title-box,
.lc-layout-inbot-drawer .side-menu-in-drawer .title-and-avatar .title-box,
.lc-layout-inbot .side-menu-in-drawer .title-and-avatar .title-box {
  display: flex;
  align-items: center;
  min-height: 28px;
}
.lc-layout-inbot-drawer .side-menu .title-and-avatar .title,
.lc-layout-inbot .side-menu .title-and-avatar .title,
.lc-layout-inbot-drawer .side-menu-in-drawer .title-and-avatar .title,
.lc-layout-inbot .side-menu-in-drawer .title-and-avatar .title {
  font-weight: 500;
}
.lc-layout-inbot-drawer .side-menu .title-and-avatar .lc-avatar,
.lc-layout-inbot .side-menu .title-and-avatar .lc-avatar,
.lc-layout-inbot-drawer .side-menu-in-drawer .title-and-avatar .lc-avatar,
.lc-layout-inbot .side-menu-in-drawer .title-and-avatar .lc-avatar {
  margin-top: 4px;
}
.lc-layout-inbot-drawer .side-menu .title-and-avatar > :not(:last-child),
.lc-layout-inbot .side-menu .title-and-avatar > :not(:last-child),
.lc-layout-inbot-drawer .side-menu-in-drawer .title-and-avatar > :not(:last-child),
.lc-layout-inbot .side-menu-in-drawer .title-and-avatar > :not(:last-child) {
  margin-right: 0.5rem;
}
.lc-layout-inbot-drawer .side-menu .feature-nav,
.lc-layout-inbot .side-menu .feature-nav,
.lc-layout-inbot-drawer .side-menu-in-drawer .feature-nav,
.lc-layout-inbot .side-menu-in-drawer .feature-nav {
  padding: 0.25rem;
  gap: 0.125rem;
}
.lc-layout-inbot-drawer .side-menu .feature-nav .feature-nav-item,
.lc-layout-inbot .side-menu .feature-nav .feature-nav-item,
.lc-layout-inbot-drawer .side-menu-in-drawer .feature-nav .feature-nav-item,
.lc-layout-inbot .side-menu-in-drawer .feature-nav .feature-nav-item {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
}
@media (max-width: 639px) {
  .lc-layout-inbot-drawer .side-menu .feature-nav .feature-nav-item,
  .lc-layout-inbot .side-menu .feature-nav .feature-nav-item,
  .lc-layout-inbot-drawer .side-menu-in-drawer .feature-nav .feature-nav-item,
  .lc-layout-inbot .side-menu-in-drawer .feature-nav .feature-nav-item {
    padding: 0.75rem 1rem;
  }
}
.lc-layout-inbot-drawer .side-menu .feature-nav .feature-nav-item svg,
.lc-layout-inbot .side-menu .feature-nav .feature-nav-item svg,
.lc-layout-inbot-drawer .side-menu-in-drawer .feature-nav .feature-nav-item svg,
.lc-layout-inbot .side-menu-in-drawer .feature-nav .feature-nav-item svg {
  height: 16px;
  width: 16px;
}
@media (max-width: 639px) {
  .lc-layout-inbot-drawer .side-menu .feature-nav .feature-nav-item svg,
  .lc-layout-inbot .side-menu .feature-nav .feature-nav-item svg,
  .lc-layout-inbot-drawer .side-menu-in-drawer .feature-nav .feature-nav-item svg,
  .lc-layout-inbot .side-menu-in-drawer .feature-nav .feature-nav-item svg {
    height: 20px;
    width: 20px;
  }
}
.lc-layout-inbot-drawer .side-menu .feature-nav .feature-nav-item:hover,
.lc-layout-inbot .side-menu .feature-nav .feature-nav-item:hover,
.lc-layout-inbot-drawer .side-menu-in-drawer .feature-nav .feature-nav-item:hover,
.lc-layout-inbot .side-menu-in-drawer .feature-nav .feature-nav-item:hover,
.lc-layout-inbot-drawer .side-menu .feature-nav .feature-nav-item.active:hover,
.lc-layout-inbot .side-menu .feature-nav .feature-nav-item.active:hover,
.lc-layout-inbot-drawer .side-menu-in-drawer .feature-nav .feature-nav-item.active:hover,
.lc-layout-inbot .side-menu-in-drawer .feature-nav .feature-nav-item.active:hover {
  background-color: #EAEAEA;
}
.lc-layout-inbot-drawer .side-menu .feature-nav .feature-nav-item.active,
.lc-layout-inbot .side-menu .feature-nav .feature-nav-item.active,
.lc-layout-inbot-drawer .side-menu-in-drawer .feature-nav .feature-nav-item.active,
.lc-layout-inbot .side-menu-in-drawer .feature-nav .feature-nav-item.active {
  background-color: #F2F2F2;
  font-weight: 500;
}
.lc-layout-inbot-drawer .side-menu .feature-nav .feature-nav-item.not-clickable,
.lc-layout-inbot .side-menu .feature-nav .feature-nav-item.not-clickable,
.lc-layout-inbot-drawer .side-menu-in-drawer .feature-nav .feature-nav-item.not-clickable,
.lc-layout-inbot .side-menu-in-drawer .feature-nav .feature-nav-item.not-clickable {
  cursor: default;
}
.lc-layout-inbot-drawer .side-menu .lc-progress-bar,
.lc-layout-inbot .side-menu .lc-progress-bar,
.lc-layout-inbot-drawer .side-menu-in-drawer .lc-progress-bar,
.lc-layout-inbot .side-menu-in-drawer .lc-progress-bar {
  width: 100%;
}
.lc-layout-inbot-drawer .side-menu .lc-progress-bar .ProgressRoot,
.lc-layout-inbot .side-menu .lc-progress-bar .ProgressRoot,
.lc-layout-inbot-drawer .side-menu-in-drawer .lc-progress-bar .ProgressRoot,
.lc-layout-inbot .side-menu-in-drawer .lc-progress-bar .ProgressRoot {
  height: 7px;
}
.lc-layout-inbot-drawer .side-menu-in-drawer,
.lc-layout-inbot .side-menu-in-drawer {
  width: 100%;
}
.lc-inbot-base {
  font-size: 14px;
}
@media (max-width: 639px) {
  .lc-inbot-base {
    font-size: 16px;
  }
}
.lc-inbot-tooltip {
  font-size: 13px;
}
