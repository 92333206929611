.lc-job-summary {
  display: flex;
  gap: 0.5rem;
  padding: 1rem 0.75rem;
  position: relative;
  background-color: var(--lc-color-bg-3);
  border-radius: 4px;
}
.lc-job-summary:hover {
  cursor: pointer;
  box-shadow: var(--lc-shadow-base);
}
.lc-job-summary .icon {
  margin-top: 3px;
}
.lc-job-summary .job-info {
  display: flex;
  gap: 0.5rem;
  flex-grow: 1;
}
.lc-job-summary .job-info .job-status-box {
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  transform: translateX(-0.25rem);
}
.lc-job-summary .job-info .job-status-box .job-time {
  color: var(--gray10);
}
.lc-job-status {
  padding: 0.125rem 8px;
  white-space: pre;
  background-color: var(--color-3);
  color: var(--color-11);
  display: flex;
  align-items: center;
}
.lc-job-status i {
  font-size: 1.3em;
}
