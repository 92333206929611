.dark-theme .test-ui {
  --lc-color-bg-1: var(--gray1);
}
.test-ui {
  --lc-color-bg-1: #fff;
  --lc-color-bg-2: var(--gray2);
  --lc-color-text-1: var(--gray12);
  --lc-color-text-2: var(--gray11);
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: var(--lc-color-bg-1);
  color: var(--color-12);
  display: flex;
  flex-direction: column;
}
.test-ui .section {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.test-ui .section .group {
  padding: 0.5rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: start;
}
