.lc-task-card {
  width: 100%;
  box-shadow: var(--lc-shadow-base);
  background-color: var(--lc-color-bg-3);
  padding: 0.75rem 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.lc-task-card:hover {
  box-shadow: var(--lc-shadow-md);
  cursor: pointer;
}
.lc-task-card .task-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
}
.lc-task-card .task-tags .tag {
  padding: 0.125rem 0.5rem;
  border-radius: 4px;
  background-color: var(--gray3);
  color: var(--gray11);
}
.lc-task-card.upcoming {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  background-color: var(--gray2);
  color: var(--gray10);
}
.lc-task-card.upcoming:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px;
  cursor: default;
}
.lc-task-card.upcoming .upcoming-tip {
  transform: translateX(-4px);
}
.lc-add-task-dialog {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem 2rem 1rem;
}
@media (max-width: 639px) {
  .lc-add-task-dialog {
    padding: 0.5rem 1.5rem;
  }
}
.lc-task-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
}
.lc-task-title .icon {
  display: flex;
  align-items: center;
}
.lc-task-desc {
  flex-grow: 1000;
  color: var(--gray11);
}
