@import "@radix-ui/colors/tomato.css";
@import "@radix-ui/colors/red.css";
@import "@radix-ui/colors/crimson.css";
@import "@radix-ui/colors/pink.css";
@import "@radix-ui/colors/plum.css";
@import "@radix-ui/colors/purple.css";
@import "@radix-ui/colors/violet.css";
@import "@radix-ui/colors/indigo.css";
@import "@radix-ui/colors/blue.css";
@import "@radix-ui/colors/cyan.css";
@import "@radix-ui/colors/teal.css";
@import "@radix-ui/colors/green.css";
@import "@radix-ui/colors/grass.css";
@import "@radix-ui/colors/orange.css";
@import "@radix-ui/colors/brown.css";
@import "@radix-ui/colors/sky.css";
@import "@radix-ui/colors/mint.css";
@import "@radix-ui/colors/lime.css";
@import "@radix-ui/colors/yellow.css";
@import "@radix-ui/colors/amber.css";
@import "@radix-ui/colors/gray.css";
@import "@radix-ui/colors/mauve.css";
@import "@radix-ui/colors/slate.css";
@import "@radix-ui/colors/sage.css";
@import "@radix-ui/colors/olive.css";
@import "@radix-ui/colors/sand.css";
@import "@radix-ui/colors/bronze.css";
@import "@radix-ui/colors/gold.css";
@import "@radix-ui/colors/tomatoDark.css";
@import "@radix-ui/colors/redDark.css";
@import "@radix-ui/colors/crimsonDark.css";
@import "@radix-ui/colors/pinkDark.css";
@import "@radix-ui/colors/plumDark.css";
@import "@radix-ui/colors/purpleDark.css";
@import "@radix-ui/colors/violetDark.css";
@import "@radix-ui/colors/indigoDark.css";
@import "@radix-ui/colors/blueDark.css";
@import "@radix-ui/colors/cyanDark.css";
@import "@radix-ui/colors/tealDark.css";
@import "@radix-ui/colors/greenDark.css";
@import "@radix-ui/colors/grassDark.css";
@import "@radix-ui/colors/orangeDark.css";
@import "@radix-ui/colors/brownDark.css";
@import "@radix-ui/colors/skyDark.css";
@import "@radix-ui/colors/mintDark.css";
@import "@radix-ui/colors/limeDark.css";
@import "@radix-ui/colors/yellowDark.css";
@import "@radix-ui/colors/amberDark.css";
@import "@radix-ui/colors/grayDark.css";
@import "@radix-ui/colors/mauveDark.css";
@import "@radix-ui/colors/slateDark.css";
@import "@radix-ui/colors/sageDark.css";
@import "@radix-ui/colors/oliveDark.css";
@import "@radix-ui/colors/sandDark.css";
@import "@radix-ui/colors/bronzeDark.css";
@import "@radix-ui/colors/goldDark.css";
.text-bold {
  font-weight: 500;
}
.error {
  color: var(--tomato9);
}
.caption {
  color: var(--gray10);
}
.font-size-28 {
  font-size: 28px;
}
@media (max-width: 639px) {
  .font-size-28 {
    font-size: 30px;
  }
}
.font-size-26 {
  font-size: 24px;
}
@media (max-width: 639px) {
  .font-size-26 {
    font-size: 28px;
  }
}
.font-size-24 {
  font-size: 24px;
}
@media (max-width: 639px) {
  .font-size-24 {
    font-size: 26px;
  }
}
.font-size-22 {
  font-size: 22px;
}
@media (max-width: 639px) {
  .font-size-22 {
    font-size: 24px;
  }
}
.font-size-20 {
  font-size: 20px;
}
@media (max-width: 639px) {
  .font-size-20 {
    font-size: 22px;
  }
}
.font-size-18 {
  font-size: 18px;
}
@media (max-width: 639px) {
  .font-size-18 {
    font-size: 20px;
  }
}
.font-size-17 {
  font-size: 17px;
}
@media (max-width: 639px) {
  .font-size-17 {
    font-size: 19px;
  }
}
.font-size-16 {
  font-size: 16px;
}
@media (max-width: 639px) {
  .font-size-16 {
    font-size: 18px;
  }
}
.font-size-15 {
  font-size: 15px;
}
@media (max-width: 639px) {
  .font-size-15 {
    font-size: 17px;
  }
}
.font-size-14 {
  font-size: 14px;
}
@media (max-width: 639px) {
  .font-size-14 {
    font-size: 16px;
  }
}
.font-size-13 {
  font-size: 13px;
}
@media (max-width: 639px) {
  .font-size-13 {
    font-size: 15px;
  }
}
.font-size-12 {
  font-size: 12px;
}
@media (max-width: 639px) {
  .font-size-12 {
    font-size: 14px;
  }
}
.font-size-11 {
  font-size: 11px;
}
@media (max-width: 639px) {
  .font-size-11 {
    font-size: 13px;
  }
}
.font-size-10 {
  font-size: 10px;
}
@media (max-width: 639px) {
  .font-size-10 {
    font-size: 12px;
  }
}
body pre {
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto;
}
body p,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6,
body div {
  margin: 0;
}
body * {
  box-sizing: border-box;
}
.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
}
img {
  vertical-align: bottom;
  -webkit-touch-callout: none;
}
a,
button,
input {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  text-align: right;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
input[type=text],
input[type=number],
input {
  pointer-events: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input[type=button],
input[type=submit],
input[type=reset],
input,
button {
  border-width: 0;
}
.dark-theme,
.dark-theme .lc-base {
  --lc-color-bg-1: #181919;
  --lc-color-bg-2: var(--gray2);
  --lc-color-bg-3: var(--gray3);
  --lc-color-bg-4: var(--gray4);
  --lc-color-bg-7: var(--gray7);
  --lc-color-inversed: var(--gray1);
  --lc-color-text: #E1E2E4;
  --lc-color-border: var(--gray4);
  --lc-shadow-xs: 0 0 0 1px rgba(168, 168, 168, 0.05);
  --lc-shadow-sm: 0 1px 2px 0 rgba(168, 168, 168, 0.05);
  --lc-shadow-base: 0 1px 3px 0 rgba(168, 168, 168, 0.1),0 1px 2px 0 rgba(168, 168, 168, 0.06);
  --lc-shadow-md: 0 4px 6px -1px rgba(168, 168, 168, 0.1),0 2px 4px -1px rgba(168, 168, 168, 0.06);
  --lc-shadow-lg: 0 10px 15px -3px rgba(168, 168, 168, 0.1),0 4px 6px -2px rgba(168, 168, 168, 0.05);
  --lc-shadow-xl: 0 20px 25px -5px rgba(168, 168, 168, 0.1),0 10px 10px -5px rgba(168, 168, 168, 0.04);
  --lc-shadow-2xl: 0 25px 50px -12px rgba(168, 168, 168, 0.25);
  --lc-shadow-outline: 0 0 0 3px rgba(66, 153, 225, 0.6);
  --lc-shadow-inner: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  --lc-shadow-none: none;
  --lc-shadow-dark-lg: rgba(168, 168, 168, 0.1) 0px 0px 0px 1px,rgba(168, 168, 168, 0.2) 0px 5px 10px,rgba(168, 168, 168, 0.4) 0px 15px 40px;
}
.dark-theme .light-only,
.dark-theme .lc-base .light-only {
  display: none;
}
.dark-theme .dark-only,
.dark-theme .lc-base .dark-only {
  display: unset;
}
.dark-only {
  display: none;
}
body {
  --lc-color-bg-1: #fff;
  --lc-color-bg-2: #fff;
  --lc-color-bg-3: #fff;
  --lc-color-bg-4: #fff;
  --lc-color-border: var(--gray6);
  background-color: var(--lc-color-bg-1);
}
.lc-base {
  font-family: Inter, -apple-system, BlinkMacSystemFont, PingFang SC, Hiragino Sans GB, noto sans, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  letter-spacing: 0.02rem;
  --lc-color-text: #27282d;
  color: var(--lc-color-text);
  --lc-color-inversed: #fff;
  --color-1: var(--gray1);
  --color-2: var(--gray2);
  --color-3: var(--gray3);
  --color-4: var(--gray4);
  --color-5: var(--gray5);
  --color-6: var(--gray6);
  --color-7: var(--gray7);
  --color-8: var(--gray8);
  --color-9: var(--gray9);
  --color-10: var(--gray10);
  --color-11: var(--gray11);
  --color-12: var(--gray12);
  --color-label: var(--gray12);
  --lc-shadow-xs: 0 0 0 1px rgba(0, 0, 0, 0.05);
  --lc-shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --lc-shadow-base: 0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --lc-shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1),0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --lc-shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --lc-shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1),0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --lc-shadow-2xl: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  --lc-shadow-outline: 0 0 0 3px rgba(66, 153, 225, 0.6);
  --lc-shadow-inner: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  --lc-shadow-none: none;
  --lc-shadow-dark-lg: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px,rgba(0, 0, 0, 0.2) 0px 5px 10px,rgba(0, 0, 0, 0.4) 0px 15px 40px;
}
.lc-base [type="button"]:focus {
  box-shadow: 0 0 0 2px var(--color-7);
}
.lc-base *,
.lc-base ::before,
.lc-base ::after {
  border-color: var(--lc-color-border);
}
.lc-base ::placeholder {
  color: var(--gray8);
}
.lc-base [data-color="tomato"] {
  --color-1: var(--tomato1);
  --color-2: var(--tomato2);
  --color-3: var(--tomato3);
  --color-4: var(--tomato4);
  --color-5: var(--tomato5);
  --color-6: var(--tomato6);
  --color-7: var(--tomato7);
  --color-8: var(--tomato8);
  --color-9: var(--tomato9);
  --color-10: var(--tomato10);
  --color-11: var(--tomato11);
  --color-12: var(--tomato12);
  --color-label: var(--tomato11);
}
.lc-base [data-color="red"] {
  --color-1: var(--red1);
  --color-2: var(--red2);
  --color-3: var(--red3);
  --color-4: var(--red4);
  --color-5: var(--red5);
  --color-6: var(--red6);
  --color-7: var(--red7);
  --color-8: var(--red8);
  --color-9: var(--red9);
  --color-10: var(--red10);
  --color-11: var(--red11);
  --color-12: var(--red12);
  --color-label: var(--red11);
}
.lc-base [data-color="crimson"] {
  --color-1: var(--crimson1);
  --color-2: var(--crimson2);
  --color-3: var(--crimson3);
  --color-4: var(--crimson4);
  --color-5: var(--crimson5);
  --color-6: var(--crimson6);
  --color-7: var(--crimson7);
  --color-8: var(--crimson8);
  --color-9: var(--crimson9);
  --color-10: var(--crimson10);
  --color-11: var(--crimson11);
  --color-12: var(--crimson12);
  --color-label: var(--crimson11);
}
.lc-base [data-color="pink"] {
  --color-1: var(--pink1);
  --color-2: var(--pink2);
  --color-3: var(--pink3);
  --color-4: var(--pink4);
  --color-5: var(--pink5);
  --color-6: var(--pink6);
  --color-7: var(--pink7);
  --color-8: var(--pink8);
  --color-9: var(--pink9);
  --color-10: var(--pink10);
  --color-11: var(--pink11);
  --color-12: var(--pink12);
  --color-label: var(--pink11);
}
.lc-base [data-color="plum"] {
  --color-1: var(--plum1);
  --color-2: var(--plum2);
  --color-3: var(--plum3);
  --color-4: var(--plum4);
  --color-5: var(--plum5);
  --color-6: var(--plum6);
  --color-7: var(--plum7);
  --color-8: var(--plum8);
  --color-9: var(--plum9);
  --color-10: var(--plum10);
  --color-11: var(--plum11);
  --color-12: var(--plum12);
  --color-label: var(--plum11);
}
.lc-base [data-color="purple"] {
  --color-1: var(--purple1);
  --color-2: var(--purple2);
  --color-3: var(--purple3);
  --color-4: var(--purple4);
  --color-5: var(--purple5);
  --color-6: var(--purple6);
  --color-7: var(--purple7);
  --color-8: var(--purple8);
  --color-9: var(--purple9);
  --color-10: var(--purple10);
  --color-11: var(--purple11);
  --color-12: var(--purple12);
  --color-label: var(--purple11);
}
.lc-base [data-color="violet"] {
  --color-1: var(--violet1);
  --color-2: var(--violet2);
  --color-3: var(--violet3);
  --color-4: var(--violet4);
  --color-5: var(--violet5);
  --color-6: var(--violet6);
  --color-7: var(--violet7);
  --color-8: var(--violet8);
  --color-9: var(--violet9);
  --color-10: var(--violet10);
  --color-11: var(--violet11);
  --color-12: var(--violet12);
  --color-label: var(--violet11);
}
.lc-base [data-color="indigo"] {
  --color-1: var(--indigo1);
  --color-2: var(--indigo2);
  --color-3: var(--indigo3);
  --color-4: var(--indigo4);
  --color-5: var(--indigo5);
  --color-6: var(--indigo6);
  --color-7: var(--indigo7);
  --color-8: var(--indigo8);
  --color-9: var(--indigo9);
  --color-10: var(--indigo10);
  --color-11: var(--indigo11);
  --color-12: var(--indigo12);
  --color-label: var(--indigo11);
}
.lc-base [data-color="blue"] {
  --color-1: var(--blue1);
  --color-2: var(--blue2);
  --color-3: var(--blue3);
  --color-4: var(--blue4);
  --color-5: var(--blue5);
  --color-6: var(--blue6);
  --color-7: var(--blue7);
  --color-8: var(--blue8);
  --color-9: var(--blue9);
  --color-10: var(--blue10);
  --color-11: var(--blue11);
  --color-12: var(--blue12);
  --color-label: var(--blue11);
}
.lc-base [data-color="cyan"] {
  --color-1: var(--cyan1);
  --color-2: var(--cyan2);
  --color-3: var(--cyan3);
  --color-4: var(--cyan4);
  --color-5: var(--cyan5);
  --color-6: var(--cyan6);
  --color-7: var(--cyan7);
  --color-8: var(--cyan8);
  --color-9: var(--cyan9);
  --color-10: var(--cyan10);
  --color-11: var(--cyan11);
  --color-12: var(--cyan12);
  --color-label: var(--cyan11);
}
.lc-base [data-color="teal"] {
  --color-1: var(--teal1);
  --color-2: var(--teal2);
  --color-3: var(--teal3);
  --color-4: var(--teal4);
  --color-5: var(--teal5);
  --color-6: var(--teal6);
  --color-7: var(--teal7);
  --color-8: var(--teal8);
  --color-9: var(--teal9);
  --color-10: var(--teal10);
  --color-11: var(--teal11);
  --color-12: var(--teal12);
  --color-label: var(--teal11);
}
.lc-base [data-color="green"] {
  --color-1: var(--green1);
  --color-2: var(--green2);
  --color-3: var(--green3);
  --color-4: var(--green4);
  --color-5: var(--green5);
  --color-6: var(--green6);
  --color-7: var(--green7);
  --color-8: var(--green8);
  --color-9: var(--green9);
  --color-10: var(--green10);
  --color-11: var(--green11);
  --color-12: var(--green12);
  --color-label: var(--green11);
}
.lc-base [data-color="grass"] {
  --color-1: var(--grass1);
  --color-2: var(--grass2);
  --color-3: var(--grass3);
  --color-4: var(--grass4);
  --color-5: var(--grass5);
  --color-6: var(--grass6);
  --color-7: var(--grass7);
  --color-8: var(--grass8);
  --color-9: var(--grass9);
  --color-10: var(--grass10);
  --color-11: var(--grass11);
  --color-12: var(--grass12);
  --color-label: var(--grass11);
}
.lc-base [data-color="orange"] {
  --color-1: var(--orange1);
  --color-2: var(--orange2);
  --color-3: var(--orange3);
  --color-4: var(--orange4);
  --color-5: var(--orange5);
  --color-6: var(--orange6);
  --color-7: var(--orange7);
  --color-8: var(--orange8);
  --color-9: var(--orange9);
  --color-10: var(--orange10);
  --color-11: var(--orange11);
  --color-12: var(--orange12);
  --color-label: var(--orange11);
}
.lc-base [data-color="brown"] {
  --color-1: var(--brown1);
  --color-2: var(--brown2);
  --color-3: var(--brown3);
  --color-4: var(--brown4);
  --color-5: var(--brown5);
  --color-6: var(--brown6);
  --color-7: var(--brown7);
  --color-8: var(--brown8);
  --color-9: var(--brown9);
  --color-10: var(--brown10);
  --color-11: var(--brown11);
  --color-12: var(--brown12);
  --color-label: var(--brown11);
}
.lc-base [data-color="bronze"] {
  --color-1: var(--bronze1);
  --color-2: var(--bronze2);
  --color-3: var(--bronze3);
  --color-4: var(--bronze4);
  --color-5: var(--bronze5);
  --color-6: var(--bronze6);
  --color-7: var(--bronze7);
  --color-8: var(--bronze8);
  --color-9: var(--bronze9);
  --color-10: var(--bronze10);
  --color-11: var(--bronze11);
  --color-12: var(--bronze12);
  --color-label: var(--bronze11);
}
.lc-base [data-color="gold"] {
  --color-1: var(--gold1);
  --color-2: var(--gold2);
  --color-3: var(--gold3);
  --color-4: var(--gold4);
  --color-5: var(--gold5);
  --color-6: var(--gold6);
  --color-7: var(--gold7);
  --color-8: var(--gold8);
  --color-9: var(--gold9);
  --color-10: var(--gold10);
  --color-11: var(--gold11);
  --color-12: var(--gold12);
  --color-label: var(--gold11);
}
.lc-base [data-color="sky"] {
  --color-1: var(--sky1);
  --color-2: var(--sky2);
  --color-3: var(--sky3);
  --color-4: var(--sky4);
  --color-5: var(--sky5);
  --color-6: var(--sky6);
  --color-7: var(--sky7);
  --color-8: var(--sky8);
  --color-9: var(--sky9);
  --color-10: var(--sky10);
  --color-11: var(--sky11);
  --color-12: var(--sky12);
  --color-label: var(--sky11);
}
.lc-base [data-color="mint"] {
  --color-1: var(--mint1);
  --color-2: var(--mint2);
  --color-3: var(--mint3);
  --color-4: var(--mint4);
  --color-5: var(--mint5);
  --color-6: var(--mint6);
  --color-7: var(--mint7);
  --color-8: var(--mint8);
  --color-9: var(--mint9);
  --color-10: var(--mint10);
  --color-11: var(--mint11);
  --color-12: var(--mint12);
  --color-label: var(--mint11);
}
.lc-base [data-color="lime"] {
  --color-1: var(--lime1);
  --color-2: var(--lime2);
  --color-3: var(--lime3);
  --color-4: var(--lime4);
  --color-5: var(--lime5);
  --color-6: var(--lime6);
  --color-7: var(--lime7);
  --color-8: var(--lime8);
  --color-9: var(--lime9);
  --color-10: var(--lime10);
  --color-11: var(--lime11);
  --color-12: var(--lime12);
  --color-label: var(--lime11);
}
.lc-base [data-color="yellow"] {
  --color-1: var(--yellow1);
  --color-2: var(--yellow2);
  --color-3: var(--yellow3);
  --color-4: var(--yellow4);
  --color-5: var(--yellow5);
  --color-6: var(--yellow6);
  --color-7: var(--yellow7);
  --color-8: var(--yellow8);
  --color-9: var(--yellow9);
  --color-10: var(--yellow10);
  --color-11: var(--yellow11);
  --color-12: var(--yellow12);
  --color-label: var(--yellow11);
}
.lc-base [data-color="amber"] {
  --color-1: var(--amber1);
  --color-2: var(--amber2);
  --color-3: var(--amber3);
  --color-4: var(--amber4);
  --color-5: var(--amber5);
  --color-6: var(--amber6);
  --color-7: var(--amber7);
  --color-8: var(--amber8);
  --color-9: var(--amber9);
  --color-10: var(--amber10);
  --color-11: var(--amber11);
  --color-12: var(--amber12);
  --color-label: var(--amber11);
}
.lc-base [data-color="gray"] {
  --color-1: var(--gray1);
  --color-2: var(--gray2);
  --color-3: var(--gray3);
  --color-4: var(--gray4);
  --color-5: var(--gray5);
  --color-6: var(--gray6);
  --color-7: var(--gray7);
  --color-8: var(--gray8);
  --color-9: var(--gray9);
  --color-10: var(--gray10);
  --color-11: var(--gray11);
  --color-12: var(--gray12);
  --color-label: var(--gray12);
}
.lc-base [data-color="mauve"] {
  --color-1: var(--mauve1);
  --color-2: var(--mauve2);
  --color-3: var(--mauve3);
  --color-4: var(--mauve4);
  --color-5: var(--mauve5);
  --color-6: var(--mauve6);
  --color-7: var(--mauve7);
  --color-8: var(--mauve8);
  --color-9: var(--mauve9);
  --color-10: var(--mauve10);
  --color-11: var(--mauve11);
  --color-12: var(--mauve12);
  --color-label: var(--mauve12);
}
.lc-base [data-color="slate"] {
  --color-1: var(--slate1);
  --color-2: var(--slate2);
  --color-3: var(--slate3);
  --color-4: var(--slate4);
  --color-5: var(--slate5);
  --color-6: var(--slate6);
  --color-7: var(--slate7);
  --color-8: var(--slate8);
  --color-9: var(--slate9);
  --color-10: var(--slate10);
  --color-11: var(--slate11);
  --color-12: var(--slate12);
  --color-label: var(--slate12);
}
.lc-base [data-color="sage"] {
  --color-1: var(--sage1);
  --color-2: var(--sage2);
  --color-3: var(--sage3);
  --color-4: var(--sage4);
  --color-5: var(--sage5);
  --color-6: var(--sage6);
  --color-7: var(--sage7);
  --color-8: var(--sage8);
  --color-9: var(--sage9);
  --color-10: var(--sage10);
  --color-11: var(--sage11);
  --color-12: var(--sage12);
  --color-label: var(--sage12);
}
.lc-base [data-color="olive"] {
  --color-1: var(--olive1);
  --color-2: var(--olive2);
  --color-3: var(--olive3);
  --color-4: var(--olive4);
  --color-5: var(--olive5);
  --color-6: var(--olive6);
  --color-7: var(--olive7);
  --color-8: var(--olive8);
  --color-9: var(--olive9);
  --color-10: var(--olive10);
  --color-11: var(--olive11);
  --color-12: var(--olive12);
  --color-label: var(--olive12);
}
.lc-base [data-color="sand"] {
  --color-1: var(--sand1);
  --color-2: var(--sand2);
  --color-3: var(--sand3);
  --color-4: var(--sand4);
  --color-5: var(--sand5);
  --color-6: var(--sand6);
  --color-7: var(--sand7);
  --color-8: var(--sand8);
  --color-9: var(--sand9);
  --color-10: var(--sand10);
  --color-11: var(--sand11);
  --color-12: var(--sand12);
  --color-label: var(--sand12);
}
.maybe-win {
  /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
  /*定义滚动条轨道 内阴影+圆角*/
  /*定义滑块 内阴影+圆角*/
}
.maybe-win ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.maybe-win ::-webkit-scrollbar-track {
  border-radius: px;
  background-color: rgba(0, 0, 0, 0.02);
}
.maybe-win ::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.05);
}
