.lc-list-box {
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0.5rem;
  gap: 0.25rem;
  margin: 0.5rem 0;
  overflow: auto;
  outline: none;
  background-color: inherit;
}
.lc-list-box > li {
  list-style: none;
}
.lc-list-box .section-seperator {
  border-top: 1px solid var(--gray6);
  margin: 4px;
}
.lc-list-box .list-box-section > ul {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0.25rem 0 0.5rem;
}
.lc-list-box .list-box-section > ul > li {
  list-style: none;
}
.lc-list-box .list-box-section .section-head {
  font-size: 0.875em;
  padding: 0.5rem 0.5rem;
  color: var(--gray10);
}
.lc-list-box .list-box-item,
.lc-list-box .list-box-item-unstyled {
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  outline: none;
}
.lc-list-box .list-box-item[aria-disabled=true],
.lc-list-box .list-box-item-unstyled[aria-disabled=true] {
  opacity: 0.4;
  cursor: not-allowed;
}
.lc-list-box .list-box-item[data-focused=true] {
  background-color: var(--color-4);
  color: var(--color-11);
}
.lc-list-box .list-box-item[aria-selected=true] {
  background-color: var(--color-10);
  color: var(--color-1);
}
.lc-list-box .list-box-item-unstyled:hover:not([aria-disabled=true]),
.lc-list-box .list-box-item-unstyled[data-keyboard-focus=true] {
  background-color: var(--gray2);
}
