.lc-task-job-detail {
  padding: 0.5rem 2rem 1.5rem;
}
@media (max-width: 639px) {
  .lc-task-job-detail {
    padding: 0.5rem 1.5rem 1.5rem;
  }
}
.lc-task-job-detail .task-category-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
}
.lc-task-job-detail .task-category-title .icon {
  display: flex;
  align-items: center;
}
