.bw-ui .newsflash {
  overflow: hidden;
}
.bw-ui .select {
  pointer-events: auto;
}
.bw-ui .choose-file {
  pointer-events: auto;
}
.bw-ui .choose-file .hidden-input {
  display: none;
}
