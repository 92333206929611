.background-video {
  background: none;
  position: absolute;
  transition: opacity 0.3s ease 0s;
  opacity: 1;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.background-video::-webkit-media-controls-play-button {
  display: none;
}
