.lc-related-items {
  padding: 0.5rem 0 0.25rem;
  /* background-color: aquamarine; */
  border-top: 0.5px solid var(--gray6);
  margin-top: 0.5rem;
  color: var(--gray11);
}
.lc-related-items .summary-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  padding: 0 0.125rem 0;
}
.lc-related-items .summary-section i {
  font-size: 1.25em;
}
.lc-related-items .item-list {
  padding: 0.25rem 0.125rem 0;
}
.lc-related-items .item-list .knowledge-item {
  display: flex;
  align-items: start;
  padding: 0.25rem 0.25rem;
  gap: 0.25rem;
}
.lc-related-items .item-list .knowledge-item i {
  margin-top: 0.25rem;
}
.lc-related-items .item-list .knowledge-item svg {
  margin-top: 0.25rem;
}
.lc-related-items .item-list > :not(:last-child) {
  margin-bottom: 0.125rem;
}
.lc-related-items .CollapsibleContent {
  overflow: hidden;
}
.lc-related-items .CollapsibleContent[data-state='open'] {
  animation: slideDown 80ms ease-out;
}
.lc-related-items .CollapsibleContent[data-state='closed'] {
  animation: slideUp 64ms ease-out;
}
@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
  }
}
@keyframes slideUp {
  from {
    height: var(--radix-collapsible-content-height);
  }
  to {
    height: 0;
  }
}
