.test-editor {
  display: flex;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background-color: white;
}
.test-editor.test-editor-ai {
  margin: 0 auto;
}
.test-editor.test-editor-ai .slate-editor .editor-area-container > [contenteditable=true] {
  min-height: var(--test-editor-area-min-height) !important;
}
.test-editor .side {
  width: 50%;
  margin: 0 0.5rem;
}
.test-editor .side:last-child:first-child {
  max-width: 1024px;
  width: 90%;
  justify-self: center;
  margin: 16px auto;
}
.test-editor .draft-side .editor {
  overflow: auto;
}
