.lc-sortable-tree-node-list,
.lc-sortable-tree-node {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: var(--lc-gap);
  cursor: default;
}
.lc-sortable-tree-overlay .lc-sortable-tree-node {
  opacity: 0.4;
}
.lc-sortable-tree-overlay .lc-sortable-tree-node > .node-head {
  background: initial;
}
.lc-sortable-tree-overlay .lc-sortable-tree-node > .node-head:hover {
  background: initial;
}
.lc-sortable-tree-node[data-active=true] > .node-head {
  background-color: var(--gray2);
}
.lc-sortable-tree-node .node-head {
  display: flex;
  align-items: center;
  padding-left: calc(var(--lc-padding-left) + 0.125rem);
  padding-right: 0.375rem;
  border-radius: 4px;
}
.lc-sortable-tree-node .node-head:hover {
  background-color: var(--gray3);
  border-radius: 4px;
}
.lc-sortable-tree-node .node-head .node-icon {
  --size: 1.125rem;
  width: var(--size);
  height: var(--size);
  min-width: var(--size);
  min-height: var(--size);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
}
.lc-sortable-tree-node .node-head .node-icon[data-enabled=true]:hover {
  background-color: var(--gray5);
}
.lc-sortable-tree-node .node-head .node-icon svg {
  transition: transform 200ms ease-out 0s;
  transform: rotateZ(0deg);
  color: var(--gray-10);
}
.lc-sortable-tree-node .node-head .node-icon[data-collapsed=false] svg {
  transform: rotateZ(90deg);
}
.lc-sortable-tree-node[data-drag-over=true] {
  background-color: var(--color-3);
}
.lc-sortable-tree-node[data-top-indicator=true]:after {
  content: "";
  position: absolute;
  top: calc(-1.5px - var(--lc-gap-half));
  right: 0;
  width: calc(100% - var(--lc-padding-left));
  height: 3px;
  background-color: var(--color-7);
}
.lc-sortable-tree-node[data-bottom-indicator=true]:after {
  content: "";
  position: absolute;
  bottom: calc(-1.5px - var(--lc-gap-half));
  right: 0;
  width: calc(100% - var(--lc-padding-left));
  height: 3px;
  background-color: var(--color-7);
}
