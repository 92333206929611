@font-face {
  font-family: 'PingFang-Light';
  font-weight: 300;
  src: local('PingFangSC-Light');
}
@font-face {
  font-family: 'PingFang-Regular';
  font-weight: 400;
  src: local('PingFangSC-Regular');
}
@font-face {
  font-family: 'PingFang-Semibold';
  font-weight: 600;
  src: local('PingFangSC-Semibold');
}
html,
body {
  margin: 0px;
  padding: 0px;
  height: 100%;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
}
.bwax-page {
  color: var(--lc-color-text);
  line-height: 1.5;
  width: 100%;
  height: 100%;
}
.bw-ui {
  font-family: Inter, -apple-system, BlinkMacSystemFont, PingFang SC, Hiragino Sans GB, noto sans, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  letter-spacing: 0.02em;
  text-align: left;
  -webkit-overflow-scrolling: touch;
  /*
        http://git.qunfengshe.com/qunfengshe/bwax-app-admin/issues/530
    */
  /* Firefox */
}
.bw-ui img {
  vertical-align: bottom;
  -webkit-touch-callout: none;
}
.bw-ui a,
.bw-ui button,
.bw-ui input {
  -webkit-tap-highlight-color: transparent;
}
.bw-ui *:focus {
  outline: 0;
}
.bw-ui .page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.bw-ui .page > .column {
  flex-basis: auto;
}
.bw-ui .row,
.bw-ui .wrapped-row {
  display: flex;
  flex-direction: row;
  flex-basis: auto;
  align-items: center;
}
.bw-ui .wrapped-row {
  flex-wrap: wrap;
}
.bw-ui .wrapped-row.ctnr {
  flex-shrink: 1;
}
.bw-ui .column,
.bw-ui .box {
  display: flex;
  flex-direction: column;
  flex-basis: 0%;
  align-items: flex-start;
}
.bw-ui .column > .column,
.bw-ui .box > .column {
  flex-basis: auto;
}
.bw-ui .imlp {
  white-space: pre-wrap;
  color: transparent;
  word-break: break-word;
}
@media (min-width: 640px) {
  .bw-ui .wHdn {
    display: none!important;
  }
}
@media (max-width: 639px) {
  .bw-ui .nHdn {
    display: none!important;
  }
}
.bw-ui s.ctnr,
.bw-ui u.ctnr,
.bw-ui i.ctnr {
  text-decoration: none;
  font-style: normal;
}
.bw-ui .column s:only-of-type.ccy,
.bw-ui .box s:only-of-type.ccy,
.bw-ui .column s:first-of-type.ccy,
.bw-ui .box s:first-of-type.ccy,
.bw-ui .column s:last-of-type.ccy,
.bw-ui .box s:last-of-type.ccy {
  flex-grow: 1;
}
.bw-ui .column s:first-of-type.ccy > .ctnr,
.bw-ui .box s:first-of-type.ccy > .ctnr {
  margin-top: auto !important;
  margin-bottom: 0 !important;
}
.bw-ui .column s:last-of-type.ccy > .ctnr,
.bw-ui .box s:last-of-type.ccy > .ctnr {
  margin-top: 0 !important;
  margin-bottom: auto !important;
}
.bw-ui .column s:only-of-type.ccy > .ctnr,
.bw-ui .box s:only-of-type.ccy > .ctnr {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.bw-ui .column s:last-of-type.ccy ~ u,
.bw-ui .box s:last-of-type.ccy ~ u {
  flex-grow: 0!important;
}
.bw-ui .row s:only-of-type.ccx,
.bw-ui .row s:first-of-type.ccx,
.bw-ui .row s:last-of-type.ccx {
  flex-grow: 1;
  max-width: 100%;
}
.bw-ui .row s:first-of-type.ccx > .ctnr {
  margin-left: auto !important;
}
.bw-ui .row s:last-of-type.ccx > .ctnr {
  margin-right: auto !important;
}
.bw-ui .row s:only-of-type.ccx > .ctnr {
  margin-left: auto !important;
  margin-right: auto !important;
}
.bw-ui .row s:last-of-type.ccx ~ u {
  flex-grow: 0!important;
}
.bw-ui .row u.ccy,
.bw-ui .row s.ccy {
  margin-top: auto;
  margin-bottom: auto;
}
.bw-ui .row u:first-of-type.acr {
  flex-grow: 1;
}
.bw-ui .row u:first-of-type.acr > .ctnr {
  align-self: flex-end !important;
}
.bw-ui .row u:first-of-type ~ s {
  flex-grow: 0!important;
}
.bw-ui .column s > .ctnr,
.bw-ui .box s > .ctnr,
.bw-ui .column u > .ctnr,
.bw-ui .box u > .ctnr {
  align-self: flex-start;
}
.bw-ui .column u:first-of-type.acb,
.bw-ui .box u:first-of-type.acb {
  flex-grow: 1;
}
.bw-ui .column u:first-of-type.acb > .ctnr,
.bw-ui .box u:first-of-type.acb > .ctnr {
  margin-top: auto !important;
  margin-bottom: 0 !important;
}
.bw-ui .column u:first-of-type.acb ~ .ccy,
.bw-ui .box u:first-of-type.acb ~ .ccy {
  flex-grow: 0!important;
}
.bw-ui u.ctnr,
.bw-ui s.ctnr,
.bw-ui i.ctnr {
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  align-self: stretch !important;
}
.bw-ui .input-radio {
  pointer-events: auto;
}
.bw-ui .input-radio .radio-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.bw-ui .input-radio .radio-option.radio-disabled {
  cursor: not-allowed;
}
.bw-ui .input-radio .radio-option .option-selector {
  border-radius: 50%;
  background-color: #ffffff;
  height: 1em;
  width: 1em;
  flex-shrink: 0;
}
.bw-ui input::-webkit-outer-spin-button,
.bw-ui input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.bw-ui input[type=number] {
  -moz-appearance: textfield;
  text-align: right;
}
.bw-ui input[type=text],
.bw-ui input[type=number] {
  pointer-events: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.bw-ui .input-checkboxes,
.bw-ui .input-checkbox {
  pointer-events: auto;
}
.bw-ui .input-checkboxes .checkbox-option,
.bw-ui .input-checkbox .checkbox-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.bw-ui .input-checkboxes .checkbox-option.checkbox-disabled,
.bw-ui .input-checkbox .checkbox-option.checkbox-disabled {
  cursor: not-allowed;
}
.bw-ui .input-checkboxes .checkbox-option .option-selector,
.bw-ui .input-checkbox .checkbox-option .option-selector {
  height: 1em;
  width: 1em;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.bw-ui .input-checkboxes .checkbox-option .option-selector .check,
.bw-ui .input-checkbox .checkbox-option .option-selector .check {
  margin-top: auto !important;
  margin-bottom: auto !important;
  transform: translate3d(0px, -1px, 0px) scale3d(1, 1, 1) rotate3d(0, 0, 1, -0.78539816rad);
  height: 0.4rem;
  width: 0.6rem;
  border-width: 0px 0px 2px 2px;
  border-color: #ffffff;
  border-style: solid;
  align-self: center;
}
.bw-ui .input-checkboxes .option-label,
.bw-ui .input-radio .option-label {
  word-break: break-word;
}
.bw-ui .ctnr {
  flex-shrink: 0;
  position: relative;
}
.bw-ui .box {
  flex-basis: auto;
}
.bw-ui .text,
.bw-ui .box {
  position: relative;
}
.bw-ui .paragraph {
  white-space: normal;
  flex-basis: 0%;
  word-break: break-word;
}
.bw-ui .paragraph > .text {
  display: inline;
  white-space: pre-line;
}
.bw-ui .paragraph > .box,
.bw-ui .paragraph .row,
.bw-ui .paragraph .wrapped-row,
.bw-ui .paragraph .column {
  display: inline-flex;
}
.bw-ui .paragraph > .link.box {
  display: inline;
}
.bw-ui .paragraph > .link.box > .text {
  display: inline;
  white-space: pre-line;
}
.bw-ui .paragraph::before {
  content: '';
  display: block;
  height: 0;
  width: 0;
  margin-bottom: -2px;
}
.bw-ui .paragraph::after {
  content: '';
  display: block;
  height: 0;
  width: 0;
  margin-top: -2px;
}
.bw-ui .text {
  white-space: pre;
}
.bw-ui .page > .nb.in-front {
  position: fixed;
}
.bw-ui .link {
  pointer-events: auto;
}
.bw-ui .button {
  pointer-events: auto;
  position: relative;
  border-width: 0;
  display: inline-flex;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                      supported by Chrome, Opera and Firefox */
}
.bw-ui .button.disabled {
  cursor: not-allowed;
}
.bw-ui .textarea {
  pointer-events: auto;
  resize: none;
  box-sizing: content-box;
  background-color: transparent;
  line-height: inherit;
}
.bw-ui .nb {
  display: flex;
  flex-direction: column;
}
.bw-ui .nb > .column {
  flex-basis: auto;
}
.bw-ui .nb.behind {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  margin: 0 !important;
  z-index: -1;
  pointer-events: none;
}
.bw-ui .nb.in-front {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  margin: 0 !important;
  pointer-events: none;
}
.bw-ui .nb.on-left {
  position: absolute;
  right: 100%;
  top: 0;
  height: 100%;
  margin: 0 !important;
  z-index: 20;
  pointer-events: none;
}
.bw-ui .nb.on-right {
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  margin: 0 !important;
  z-index: 20;
  pointer-events: none;
}
.bw-ui .nb.above {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  z-index: 20;
  margin: 0 !important;
  pointer-events: none;
}
.bw-ui .nb.below {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
  z-index: 20;
  margin: 0 !important;
  pointer-events: none;
}
@media (min-width: 640px) {
  .bw-ui .row.spacing-4 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px;
  }
  .bw-ui .row.spacing-4 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px;
  }
  .bw-ui .row.spacing-4 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px;
  }
  .bw-ui .row.spacing-4 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px;
  }
  .bw-ui .row.spacing-4-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px !important;
  }
  .bw-ui .row.spacing-4-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px !important;
  }
  .bw-ui .row.spacing-4-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px !important;
  }
  .bw-ui .row.spacing-4-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px !important;
  }
  .bw-ui .row.spacing-4-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px !important;
  }
  .bw-ui .row.spacing-4-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px !important;
  }
  .bw-ui .row.spacing-4-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px !important;
  }
  .bw-ui .row.spacing-4-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px !important;
  }
  .bw-ui .row.spacing-4-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px !important;
  }
  .bw-ui .row.spacing-4-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px !important;
  }
  .bw-ui .row.spacing-4-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px !important;
  }
  .bw-ui .row.spacing-4-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px !important;
  }
}
@media (max-width: 639px) {
  .bw-ui .row.spacing-4 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px;
  }
  .bw-ui .row.spacing-4 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px;
  }
  .bw-ui .row.spacing-4 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px;
  }
  .bw-ui .row.spacing-4 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px;
  }
  .bw-ui .row.spacing-4-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px !important;
  }
  .bw-ui .row.spacing-4-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px !important;
  }
  .bw-ui .row.spacing-4-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px !important;
  }
  .bw-ui .row.spacing-4-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px !important;
  }
  .bw-ui .row.spacing-4-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px !important;
  }
  .bw-ui .row.spacing-4-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px !important;
  }
  .bw-ui .row.spacing-4-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px !important;
  }
  .bw-ui .row.spacing-4-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px !important;
  }
  .bw-ui .row.spacing-4-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px !important;
  }
  .bw-ui .row.spacing-4-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px !important;
  }
  .bw-ui .row.spacing-4-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px !important;
  }
  .bw-ui .row.spacing-4-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-4-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-4-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 4px !important;
  }
}
@media (min-width: 640px) {
  .bw-ui .row.spacing-8 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px;
  }
  .bw-ui .row.spacing-8 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px;
  }
  .bw-ui .row.spacing-8 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px;
  }
  .bw-ui .row.spacing-8 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px;
  }
  .bw-ui .row.spacing-8-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px !important;
  }
  .bw-ui .row.spacing-8-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px !important;
  }
  .bw-ui .row.spacing-8-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px !important;
  }
  .bw-ui .row.spacing-8-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px !important;
  }
  .bw-ui .row.spacing-8-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px !important;
  }
  .bw-ui .row.spacing-8-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px !important;
  }
  .bw-ui .row.spacing-8-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px !important;
  }
  .bw-ui .row.spacing-8-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px !important;
  }
  .bw-ui .row.spacing-8-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px !important;
  }
  .bw-ui .row.spacing-8-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px !important;
  }
  .bw-ui .row.spacing-8-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px !important;
  }
  .bw-ui .row.spacing-8-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px !important;
  }
}
@media (max-width: 639px) {
  .bw-ui .row.spacing-8 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px;
  }
  .bw-ui .row.spacing-8 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px;
  }
  .bw-ui .row.spacing-8 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px;
  }
  .bw-ui .row.spacing-8 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px;
  }
  .bw-ui .row.spacing-8-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px !important;
  }
  .bw-ui .row.spacing-8-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px !important;
  }
  .bw-ui .row.spacing-8-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px !important;
  }
  .bw-ui .row.spacing-8-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px !important;
  }
  .bw-ui .row.spacing-8-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px !important;
  }
  .bw-ui .row.spacing-8-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px !important;
  }
  .bw-ui .row.spacing-8-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px !important;
  }
  .bw-ui .row.spacing-8-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px !important;
  }
  .bw-ui .row.spacing-8-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px !important;
  }
  .bw-ui .row.spacing-8-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px !important;
  }
  .bw-ui .row.spacing-8-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px !important;
  }
  .bw-ui .row.spacing-8-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-8-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-8-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 8px !important;
  }
}
@media (min-width: 640px) {
  .bw-ui .row.spacing-12 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px;
  }
  .bw-ui .row.spacing-12 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px;
  }
  .bw-ui .row.spacing-12 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px;
  }
  .bw-ui .row.spacing-12 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px;
  }
  .bw-ui .row.spacing-12-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px !important;
  }
  .bw-ui .row.spacing-12-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px !important;
  }
  .bw-ui .row.spacing-12-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px !important;
  }
  .bw-ui .row.spacing-12-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px !important;
  }
  .bw-ui .row.spacing-12-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px !important;
  }
  .bw-ui .row.spacing-12-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px !important;
  }
  .bw-ui .row.spacing-12-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px !important;
  }
  .bw-ui .row.spacing-12-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px !important;
  }
  .bw-ui .row.spacing-12-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px !important;
  }
  .bw-ui .row.spacing-12-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px !important;
  }
  .bw-ui .row.spacing-12-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px !important;
  }
  .bw-ui .row.spacing-12-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px !important;
  }
}
@media (max-width: 639px) {
  .bw-ui .row.spacing-12 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px;
  }
  .bw-ui .row.spacing-12 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px;
  }
  .bw-ui .row.spacing-12 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px;
  }
  .bw-ui .row.spacing-12 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px;
  }
  .bw-ui .row.spacing-12-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px !important;
  }
  .bw-ui .row.spacing-12-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px !important;
  }
  .bw-ui .row.spacing-12-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px !important;
  }
  .bw-ui .row.spacing-12-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px !important;
  }
  .bw-ui .row.spacing-12-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px !important;
  }
  .bw-ui .row.spacing-12-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px !important;
  }
  .bw-ui .row.spacing-12-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px !important;
  }
  .bw-ui .row.spacing-12-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px !important;
  }
  .bw-ui .row.spacing-12-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px !important;
  }
  .bw-ui .row.spacing-12-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px !important;
  }
  .bw-ui .row.spacing-12-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px !important;
  }
  .bw-ui .row.spacing-12-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-12-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-12-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 12px !important;
  }
}
@media (min-width: 640px) {
  .bw-ui .row.spacing-16 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px;
  }
  .bw-ui .row.spacing-16 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px;
  }
  .bw-ui .row.spacing-16 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px;
  }
  .bw-ui .row.spacing-16 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px;
  }
  .bw-ui .row.spacing-16-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px !important;
  }
  .bw-ui .row.spacing-16-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px !important;
  }
  .bw-ui .row.spacing-16-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px !important;
  }
  .bw-ui .row.spacing-16-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px !important;
  }
  .bw-ui .row.spacing-16-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px !important;
  }
  .bw-ui .row.spacing-16-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px !important;
  }
  .bw-ui .row.spacing-16-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px !important;
  }
  .bw-ui .row.spacing-16-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px !important;
  }
  .bw-ui .row.spacing-16-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px !important;
  }
  .bw-ui .row.spacing-16-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px !important;
  }
  .bw-ui .row.spacing-16-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px !important;
  }
  .bw-ui .row.spacing-16-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px !important;
  }
}
@media (max-width: 639px) {
  .bw-ui .row.spacing-16 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px;
  }
  .bw-ui .row.spacing-16 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px;
  }
  .bw-ui .row.spacing-16 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px;
  }
  .bw-ui .row.spacing-16 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px;
  }
  .bw-ui .row.spacing-16-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px !important;
  }
  .bw-ui .row.spacing-16-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px !important;
  }
  .bw-ui .row.spacing-16-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px !important;
  }
  .bw-ui .row.spacing-16-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px !important;
  }
  .bw-ui .row.spacing-16-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px !important;
  }
  .bw-ui .row.spacing-16-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px !important;
  }
  .bw-ui .row.spacing-16-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px !important;
  }
  .bw-ui .row.spacing-16-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px !important;
  }
  .bw-ui .row.spacing-16-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px !important;
  }
  .bw-ui .row.spacing-16-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px !important;
  }
  .bw-ui .row.spacing-16-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px !important;
  }
  .bw-ui .row.spacing-16-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-16-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-16-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 16px !important;
  }
}
@media (min-width: 640px) {
  .bw-ui .row.spacing-20 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px;
  }
  .bw-ui .row.spacing-20 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px;
  }
  .bw-ui .row.spacing-20 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px;
  }
  .bw-ui .row.spacing-20 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px;
  }
  .bw-ui .row.spacing-20-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px !important;
  }
  .bw-ui .row.spacing-20-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px !important;
  }
  .bw-ui .row.spacing-20-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px !important;
  }
  .bw-ui .row.spacing-20-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px !important;
  }
  .bw-ui .row.spacing-20-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px !important;
  }
  .bw-ui .row.spacing-20-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px !important;
  }
  .bw-ui .row.spacing-20-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px !important;
  }
  .bw-ui .row.spacing-20-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px !important;
  }
  .bw-ui .row.spacing-20-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px !important;
  }
  .bw-ui .row.spacing-20-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px !important;
  }
  .bw-ui .row.spacing-20-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px !important;
  }
  .bw-ui .row.spacing-20-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px !important;
  }
}
@media (max-width: 639px) {
  .bw-ui .row.spacing-20 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px;
  }
  .bw-ui .row.spacing-20 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px;
  }
  .bw-ui .row.spacing-20 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px;
  }
  .bw-ui .row.spacing-20 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px;
  }
  .bw-ui .row.spacing-20-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px !important;
  }
  .bw-ui .row.spacing-20-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px !important;
  }
  .bw-ui .row.spacing-20-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px !important;
  }
  .bw-ui .row.spacing-20-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px !important;
  }
  .bw-ui .row.spacing-20-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px !important;
  }
  .bw-ui .row.spacing-20-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px !important;
  }
  .bw-ui .row.spacing-20-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px !important;
  }
  .bw-ui .row.spacing-20-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px !important;
  }
  .bw-ui .row.spacing-20-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px !important;
  }
  .bw-ui .row.spacing-20-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px !important;
  }
  .bw-ui .row.spacing-20-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px !important;
  }
  .bw-ui .row.spacing-20-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-20-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-20-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 20px !important;
  }
}
@media (min-width: 640px) {
  .bw-ui .row.spacing-24 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px;
  }
  .bw-ui .row.spacing-24 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px;
  }
  .bw-ui .row.spacing-24 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px;
  }
  .bw-ui .row.spacing-24 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px;
  }
  .bw-ui .row.spacing-24-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px !important;
  }
  .bw-ui .row.spacing-24-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px !important;
  }
  .bw-ui .row.spacing-24-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px !important;
  }
  .bw-ui .row.spacing-24-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px !important;
  }
  .bw-ui .row.spacing-24-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px !important;
  }
  .bw-ui .row.spacing-24-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px !important;
  }
  .bw-ui .row.spacing-24-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px !important;
  }
  .bw-ui .row.spacing-24-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px !important;
  }
  .bw-ui .row.spacing-24-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px !important;
  }
  .bw-ui .row.spacing-24-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px !important;
  }
  .bw-ui .row.spacing-24-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px !important;
  }
  .bw-ui .row.spacing-24-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px !important;
  }
}
@media (max-width: 639px) {
  .bw-ui .row.spacing-24 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px;
  }
  .bw-ui .row.spacing-24 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px;
  }
  .bw-ui .row.spacing-24 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px;
  }
  .bw-ui .row.spacing-24 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px;
  }
  .bw-ui .row.spacing-24-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px !important;
  }
  .bw-ui .row.spacing-24-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px !important;
  }
  .bw-ui .row.spacing-24-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px !important;
  }
  .bw-ui .row.spacing-24-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px !important;
  }
  .bw-ui .row.spacing-24-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px !important;
  }
  .bw-ui .row.spacing-24-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px !important;
  }
  .bw-ui .row.spacing-24-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px !important;
  }
  .bw-ui .row.spacing-24-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px !important;
  }
  .bw-ui .row.spacing-24-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px !important;
  }
  .bw-ui .row.spacing-24-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px !important;
  }
  .bw-ui .row.spacing-24-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px !important;
  }
  .bw-ui .row.spacing-24-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-24-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-24-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 24px !important;
  }
}
@media (min-width: 640px) {
  .bw-ui .row.spacing-32 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px;
  }
  .bw-ui .row.spacing-32 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px;
  }
  .bw-ui .row.spacing-32 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px;
  }
  .bw-ui .row.spacing-32 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px;
  }
  .bw-ui .row.spacing-32-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px !important;
  }
  .bw-ui .row.spacing-32-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px !important;
  }
  .bw-ui .row.spacing-32-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px !important;
  }
  .bw-ui .row.spacing-32-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px !important;
  }
  .bw-ui .row.spacing-32-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px !important;
  }
  .bw-ui .row.spacing-32-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px !important;
  }
  .bw-ui .row.spacing-32-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px !important;
  }
  .bw-ui .row.spacing-32-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px !important;
  }
  .bw-ui .row.spacing-32-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px !important;
  }
  .bw-ui .row.spacing-32-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px !important;
  }
  .bw-ui .row.spacing-32-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px !important;
  }
  .bw-ui .row.spacing-32-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px !important;
  }
}
@media (max-width: 639px) {
  .bw-ui .row.spacing-32 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px;
  }
  .bw-ui .row.spacing-32 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px;
  }
  .bw-ui .row.spacing-32 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px;
  }
  .bw-ui .row.spacing-32 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px;
  }
  .bw-ui .row.spacing-32-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px !important;
  }
  .bw-ui .row.spacing-32-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px !important;
  }
  .bw-ui .row.spacing-32-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px !important;
  }
  .bw-ui .row.spacing-32-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px !important;
  }
  .bw-ui .row.spacing-32-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px !important;
  }
  .bw-ui .row.spacing-32-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px !important;
  }
  .bw-ui .row.spacing-32-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px !important;
  }
  .bw-ui .row.spacing-32-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px !important;
  }
  .bw-ui .row.spacing-32-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px !important;
  }
  .bw-ui .row.spacing-32-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px !important;
  }
  .bw-ui .row.spacing-32-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px !important;
  }
  .bw-ui .row.spacing-32-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .radio-option.spacing-32-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .checkbox-option.spacing-32-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-left: 32px !important;
  }
}
@media (min-width: 640px) {
  .bw-ui .column.spacing-4 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px;
  }
  .bw-ui .column.spacing-4 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px;
  }
  .bw-ui .column.spacing-4 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px;
  }
  .bw-ui .column.spacing-4 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px;
  }
  .bw-ui .column.spacing-4-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px !important;
  }
  .bw-ui .column.spacing-4-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px !important;
  }
  .bw-ui .column.spacing-4-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px !important;
  }
  .bw-ui .column.spacing-4-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px !important;
  }
  .bw-ui .column.spacing-4-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px !important;
  }
  .bw-ui .column.spacing-4-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px !important;
  }
  .bw-ui .column.spacing-4-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px !important;
  }
  .bw-ui .column.spacing-4-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px !important;
  }
  .bw-ui .column.spacing-4-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px !important;
  }
  .bw-ui .column.spacing-4-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px !important;
  }
  .bw-ui .column.spacing-4-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px !important;
  }
  .bw-ui .column.spacing-4-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px !important;
  }
}
@media (max-width: 639px) {
  .bw-ui .column.spacing-4 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px;
  }
  .bw-ui .column.spacing-4 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px;
  }
  .bw-ui .column.spacing-4 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px;
  }
  .bw-ui .column.spacing-4 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px;
  }
  .bw-ui .column.spacing-4-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px !important;
  }
  .bw-ui .column.spacing-4-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px !important;
  }
  .bw-ui .column.spacing-4-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px !important;
  }
  .bw-ui .column.spacing-4-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px !important;
  }
  .bw-ui .column.spacing-4-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px !important;
  }
  .bw-ui .column.spacing-4-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px !important;
  }
  .bw-ui .column.spacing-4-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px !important;
  }
  .bw-ui .column.spacing-4-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px !important;
  }
  .bw-ui .column.spacing-4-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px !important;
  }
  .bw-ui .column.spacing-4-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px !important;
  }
  .bw-ui .column.spacing-4-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px !important;
  }
  .bw-ui .column.spacing-4-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-4-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 4px !important;
  }
}
@media (min-width: 640px) {
  .bw-ui .column.spacing-8 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px;
  }
  .bw-ui .column.spacing-8 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px;
  }
  .bw-ui .column.spacing-8 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px;
  }
  .bw-ui .column.spacing-8 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px;
  }
  .bw-ui .column.spacing-8-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px !important;
  }
  .bw-ui .column.spacing-8-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px !important;
  }
  .bw-ui .column.spacing-8-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px !important;
  }
  .bw-ui .column.spacing-8-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px !important;
  }
  .bw-ui .column.spacing-8-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px !important;
  }
  .bw-ui .column.spacing-8-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px !important;
  }
  .bw-ui .column.spacing-8-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px !important;
  }
  .bw-ui .column.spacing-8-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px !important;
  }
  .bw-ui .column.spacing-8-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px !important;
  }
  .bw-ui .column.spacing-8-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px !important;
  }
  .bw-ui .column.spacing-8-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px !important;
  }
  .bw-ui .column.spacing-8-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px !important;
  }
}
@media (max-width: 639px) {
  .bw-ui .column.spacing-8 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px;
  }
  .bw-ui .column.spacing-8 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px;
  }
  .bw-ui .column.spacing-8 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px;
  }
  .bw-ui .column.spacing-8 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px;
  }
  .bw-ui .column.spacing-8-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px !important;
  }
  .bw-ui .column.spacing-8-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px !important;
  }
  .bw-ui .column.spacing-8-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px !important;
  }
  .bw-ui .column.spacing-8-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px !important;
  }
  .bw-ui .column.spacing-8-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px !important;
  }
  .bw-ui .column.spacing-8-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px !important;
  }
  .bw-ui .column.spacing-8-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px !important;
  }
  .bw-ui .column.spacing-8-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px !important;
  }
  .bw-ui .column.spacing-8-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px !important;
  }
  .bw-ui .column.spacing-8-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px !important;
  }
  .bw-ui .column.spacing-8-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px !important;
  }
  .bw-ui .column.spacing-8-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-8-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 8px !important;
  }
}
@media (min-width: 640px) {
  .bw-ui .column.spacing-12 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px;
  }
  .bw-ui .column.spacing-12 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px;
  }
  .bw-ui .column.spacing-12 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px;
  }
  .bw-ui .column.spacing-12 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px;
  }
  .bw-ui .column.spacing-12-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px !important;
  }
  .bw-ui .column.spacing-12-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px !important;
  }
  .bw-ui .column.spacing-12-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px !important;
  }
  .bw-ui .column.spacing-12-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px !important;
  }
  .bw-ui .column.spacing-12-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px !important;
  }
  .bw-ui .column.spacing-12-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px !important;
  }
  .bw-ui .column.spacing-12-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px !important;
  }
  .bw-ui .column.spacing-12-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px !important;
  }
  .bw-ui .column.spacing-12-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px !important;
  }
  .bw-ui .column.spacing-12-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px !important;
  }
  .bw-ui .column.spacing-12-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px !important;
  }
  .bw-ui .column.spacing-12-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px !important;
  }
}
@media (max-width: 639px) {
  .bw-ui .column.spacing-12 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px;
  }
  .bw-ui .column.spacing-12 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px;
  }
  .bw-ui .column.spacing-12 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px;
  }
  .bw-ui .column.spacing-12 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px;
  }
  .bw-ui .column.spacing-12-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px !important;
  }
  .bw-ui .column.spacing-12-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px !important;
  }
  .bw-ui .column.spacing-12-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px !important;
  }
  .bw-ui .column.spacing-12-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px !important;
  }
  .bw-ui .column.spacing-12-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px !important;
  }
  .bw-ui .column.spacing-12-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px !important;
  }
  .bw-ui .column.spacing-12-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px !important;
  }
  .bw-ui .column.spacing-12-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px !important;
  }
  .bw-ui .column.spacing-12-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px !important;
  }
  .bw-ui .column.spacing-12-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px !important;
  }
  .bw-ui .column.spacing-12-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px !important;
  }
  .bw-ui .column.spacing-12-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-12-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 12px !important;
  }
}
@media (min-width: 640px) {
  .bw-ui .column.spacing-16 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px;
  }
  .bw-ui .column.spacing-16 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px;
  }
  .bw-ui .column.spacing-16 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px;
  }
  .bw-ui .column.spacing-16 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px;
  }
  .bw-ui .column.spacing-16-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px !important;
  }
  .bw-ui .column.spacing-16-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px !important;
  }
  .bw-ui .column.spacing-16-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px !important;
  }
  .bw-ui .column.spacing-16-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px !important;
  }
  .bw-ui .column.spacing-16-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px !important;
  }
  .bw-ui .column.spacing-16-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px !important;
  }
  .bw-ui .column.spacing-16-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px !important;
  }
  .bw-ui .column.spacing-16-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px !important;
  }
  .bw-ui .column.spacing-16-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px !important;
  }
  .bw-ui .column.spacing-16-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px !important;
  }
  .bw-ui .column.spacing-16-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px !important;
  }
  .bw-ui .column.spacing-16-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px !important;
  }
}
@media (max-width: 639px) {
  .bw-ui .column.spacing-16 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px;
  }
  .bw-ui .column.spacing-16 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px;
  }
  .bw-ui .column.spacing-16 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px;
  }
  .bw-ui .column.spacing-16 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px;
  }
  .bw-ui .column.spacing-16-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px !important;
  }
  .bw-ui .column.spacing-16-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px !important;
  }
  .bw-ui .column.spacing-16-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px !important;
  }
  .bw-ui .column.spacing-16-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px !important;
  }
  .bw-ui .column.spacing-16-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px !important;
  }
  .bw-ui .column.spacing-16-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px !important;
  }
  .bw-ui .column.spacing-16-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px !important;
  }
  .bw-ui .column.spacing-16-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px !important;
  }
  .bw-ui .column.spacing-16-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px !important;
  }
  .bw-ui .column.spacing-16-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px !important;
  }
  .bw-ui .column.spacing-16-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px !important;
  }
  .bw-ui .column.spacing-16-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-16-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 16px !important;
  }
}
@media (min-width: 640px) {
  .bw-ui .column.spacing-20 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px;
  }
  .bw-ui .column.spacing-20 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px;
  }
  .bw-ui .column.spacing-20 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px;
  }
  .bw-ui .column.spacing-20 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px;
  }
  .bw-ui .column.spacing-20-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px !important;
  }
  .bw-ui .column.spacing-20-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px !important;
  }
  .bw-ui .column.spacing-20-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px !important;
  }
  .bw-ui .column.spacing-20-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px !important;
  }
  .bw-ui .column.spacing-20-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px !important;
  }
  .bw-ui .column.spacing-20-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px !important;
  }
  .bw-ui .column.spacing-20-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px !important;
  }
  .bw-ui .column.spacing-20-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px !important;
  }
  .bw-ui .column.spacing-20-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px !important;
  }
  .bw-ui .column.spacing-20-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px !important;
  }
  .bw-ui .column.spacing-20-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px !important;
  }
  .bw-ui .column.spacing-20-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px !important;
  }
}
@media (max-width: 639px) {
  .bw-ui .column.spacing-20 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px;
  }
  .bw-ui .column.spacing-20 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px;
  }
  .bw-ui .column.spacing-20 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px;
  }
  .bw-ui .column.spacing-20 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px;
  }
  .bw-ui .column.spacing-20-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px !important;
  }
  .bw-ui .column.spacing-20-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px !important;
  }
  .bw-ui .column.spacing-20-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px !important;
  }
  .bw-ui .column.spacing-20-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px !important;
  }
  .bw-ui .column.spacing-20-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px !important;
  }
  .bw-ui .column.spacing-20-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px !important;
  }
  .bw-ui .column.spacing-20-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px !important;
  }
  .bw-ui .column.spacing-20-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px !important;
  }
  .bw-ui .column.spacing-20-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px !important;
  }
  .bw-ui .column.spacing-20-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px !important;
  }
  .bw-ui .column.spacing-20-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px !important;
  }
  .bw-ui .column.spacing-20-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-20-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 20px !important;
  }
}
@media (min-width: 640px) {
  .bw-ui .column.spacing-24 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px;
  }
  .bw-ui .column.spacing-24 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px;
  }
  .bw-ui .column.spacing-24 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px;
  }
  .bw-ui .column.spacing-24 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px;
  }
  .bw-ui .column.spacing-24-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px !important;
  }
  .bw-ui .column.spacing-24-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px !important;
  }
  .bw-ui .column.spacing-24-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px !important;
  }
  .bw-ui .column.spacing-24-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px !important;
  }
  .bw-ui .column.spacing-24-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px !important;
  }
  .bw-ui .column.spacing-24-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px !important;
  }
  .bw-ui .column.spacing-24-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px !important;
  }
  .bw-ui .column.spacing-24-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px !important;
  }
  .bw-ui .column.spacing-24-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px !important;
  }
  .bw-ui .column.spacing-24-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px !important;
  }
  .bw-ui .column.spacing-24-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px !important;
  }
  .bw-ui .column.spacing-24-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px !important;
  }
}
@media (max-width: 639px) {
  .bw-ui .column.spacing-24 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px;
  }
  .bw-ui .column.spacing-24 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px;
  }
  .bw-ui .column.spacing-24 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px;
  }
  .bw-ui .column.spacing-24 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px;
  }
  .bw-ui .column.spacing-24-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px !important;
  }
  .bw-ui .column.spacing-24-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px !important;
  }
  .bw-ui .column.spacing-24-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px !important;
  }
  .bw-ui .column.spacing-24-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px !important;
  }
  .bw-ui .column.spacing-24-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px !important;
  }
  .bw-ui .column.spacing-24-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px !important;
  }
  .bw-ui .column.spacing-24-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px !important;
  }
  .bw-ui .column.spacing-24-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px !important;
  }
  .bw-ui .column.spacing-24-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px !important;
  }
  .bw-ui .column.spacing-24-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px !important;
  }
  .bw-ui .column.spacing-24-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px !important;
  }
  .bw-ui .column.spacing-24-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-24-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 24px !important;
  }
}
@media (min-width: 640px) {
  .bw-ui .column.spacing-32 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32 > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px;
  }
  .bw-ui .column.spacing-32 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px;
  }
  .bw-ui .column.spacing-32 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px;
  }
  .bw-ui .column.spacing-32 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32 > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px;
  }
  .bw-ui .column.spacing-32-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px !important;
  }
  .bw-ui .column.spacing-32-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px !important;
  }
  .bw-ui .column.spacing-32-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px !important;
  }
  .bw-ui .column.spacing-32-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32-h:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px !important;
  }
  .bw-ui .column.spacing-32-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32-w > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px !important;
  }
  .bw-ui .column.spacing-32-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px !important;
  }
  .bw-ui .column.spacing-32-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px !important;
  }
  .bw-ui .column.spacing-32-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32-w > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px !important;
  }
  .bw-ui .column.spacing-32-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px !important;
  }
  .bw-ui .column.spacing-32-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px !important;
  }
  .bw-ui .column.spacing-32-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px !important;
  }
  .bw-ui .column.spacing-32-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32-h-w:hover > :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px !important;
  }
}
@media (max-width: 639px) {
  .bw-ui .column.spacing-32 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32 > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px;
  }
  .bw-ui .column.spacing-32 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px;
  }
  .bw-ui .column.spacing-32 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px;
  }
  .bw-ui .column.spacing-32 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32 > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px;
  }
  .bw-ui .column.spacing-32-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px !important;
  }
  .bw-ui .column.spacing-32-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px !important;
  }
  .bw-ui .column.spacing-32-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px !important;
  }
  .bw-ui .column.spacing-32-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32-h:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px !important;
  }
  .bw-ui .column.spacing-32-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32-n > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px !important;
  }
  .bw-ui .column.spacing-32-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px !important;
  }
  .bw-ui .column.spacing-32-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px !important;
  }
  .bw-ui .column.spacing-32-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32-n > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px !important;
  }
  .bw-ui .column.spacing-32-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px !important;
  }
  .bw-ui .column.spacing-32-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px !important;
  }
  .bw-ui .column.spacing-32-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px !important;
  }
  .bw-ui .column.spacing-32-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden),
  .bw-ui .page.spacing-32-h-n:hover > :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden) {
    margin-top: 32px !important;
  }
}
.bw-ui .Typewriter {
  width: 100%;
}
@media (max-width: 639px) {
  .bw-ui .wide-only {
    display: none !important;
  }
}
@media (min-width: 640px) {
  .bw-ui .narrow-only {
    display: none !important;
  }
}
