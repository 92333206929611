.sandbox-indicator {
  position: fixed;
  z-index: 5000;
  width: 100%;
  bottom: 0;
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  transition-duration: 300ms;
  pointer-events: none;
}
.sandbox-indicator.not-shown {
  opacity: 0;
}
@media (max-width: 639px) {
  .sandbox-indicator {
    justify-content: center;
  }
}
.sandbox-indicator .sandbox-indicator-panel {
  position: relative;
  border-radius: 8px;
  padding: 2.5rem 2.5rem 2rem 2rem;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
  background-color: var(--yellow6);
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-line;
  font-size: 14px;
  pointer-events: auto;
}
.sandbox-indicator .sandbox-indicator-panel p:not(:last-child) {
  margin-bottom: 0.25rem;
}
.sandbox-indicator .sandbox-indicator-panel .close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  opacity: 0.7;
  cursor: pointer;
}
