.lc-session-list-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
}
.lc-session-list-container .session-title,
.lc-session-list-container .setting-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 0.5rem 0.75rem 0.75rem;
  margin: 0rem 0.5rem;
  cursor: pointer;
  user-select: none;
  border-radius: 4px;
}
@media (min-width: 640px) {
  .lc-session-list-container .session-title,
  .lc-session-list-container .setting-item {
    padding: 0.625rem 0.5rem 0.625rem 0.75rem;
    margin: 0rem 0.5rem;
  }
}
.lc-session-list-container .session-title:first-child,
.lc-session-list-container .setting-item:first-child {
  margin-top: 0;
}
.lc-session-list-container .session-title svg,
.lc-session-list-container .setting-item svg {
  flex-shrink: 0;
  width: 14px;
  height: 14px;
}
.lc-session-list-container .new-session {
  border: 0.5px solid var(--gray6);
  cursor: pointer;
  user-select: none;
}
.lc-session-list-container .new-session:hover {
  background-color: var(--gray3);
}
.lc-session-list-container .new-session:active {
  background-color: var(--gray4);
}
.lc-session-list-container .new-session svg {
  flex-shrink: 0;
  width: 14px;
  height: 14px;
}
.lc-session-list-container .session-list {
  overflow: auto;
  margin-top: 0.5rem;
  flex-grow: 1000;
}
.lc-session-list-container .session-list > :not(:last-child) {
  margin-bottom: 0.125rem;
}
.lc-session-list-container .session-title {
  gap: 0.5rem;
  justify-content: space-between;
}
.lc-session-list-container .session-title.active {
  background-color: var(--gray2);
  cursor: default;
}
.lc-session-list-container .session-title:hover {
  background-color: var(--gray3);
}
.lc-session-list-container .session-title .icon-and-title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.lc-session-list-container .session-title .icon-and-title i {
  opacity: 0.85;
}
@media (min-width: 640px) {
  .lc-session-list-container .session-title:hover > .op-icons {
    display: flex;
  }
}
.lc-session-list-container .session-title .op-icons {
  display: flex;
}
@media (min-width: 640px) {
  .lc-session-list-container .session-title .op-icons {
    display: none;
  }
}
