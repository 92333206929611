.lc-subscription-payment {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
}
.lc-subscription-payment img {
  width: 1em;
  height: 1em;
  margin-right: 0.5rem;
}
.lc-subscription-payment .select-plan {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.lc-subscription-payment .select-plan .plan-box {
  flex-grow: 1000;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  border-radius: 8px;
  background-color: var(--mauve3);
  border: 1px solid transparent;
}
.lc-subscription-payment .select-plan .plan-box[data-selected=true] {
  background-color: unset;
  border: 1px solid var(--violet8);
}
.lc-subscription-payment .select-plan .plan-box .title-line {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}
.lc-subscription-payment .select-plan .plan-box .title-line .title {
  display: flex;
  align-items: center;
}
.lc-subscription-payment .select-plan .plan-box .detail {
  color: var(--gray11);
  padding: 0.5rem 0.25rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.lc-subscription-payment .select-plan .plan-box .detail .desc-line {
  margin-bottom: 0.25rem;
}
.lc-subscription-payment .instruction {
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.lc-subscription-payment .term-list {
  display: flex;
  gap: 0.75rem;
}
.lc-subscription-payment .term-list .term {
  padding: 0.5rem 1rem;
  border-radius: 6px;
  background-color: var(--mauve3);
  border: 1px solid transparent;
  user-select: none;
}
.lc-subscription-payment .term-list .term[data-selected=true] {
  background-color: unset;
  border: 1px solid var(--violet8);
}
.lc-subscription-payment .billing-lines {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  color: var(--gray11);
}
.lc-subscription-payment .billing-lines .billing-line {
  display: flex;
  justify-content: space-between;
}
.lc-subscription-payment .billing-lines .billing-line .billing-amount {
  display: flex;
  gap: 0.25rem;
}
.lc-subscription-payment .billing-total {
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
