.lc-text-input {
  padding: 0.5rem;
  border-radius: 2px;
  display: flex;
  align-items: center;
}
.lc-text-input .adornment {
  display: flex;
  padding: 0 0.25rem;
  opacity: 0.8;
}
.lc-text-input input {
  flex-grow: 1;
  background: transparent;
  width: 100%;
}
.lc-text-input.styled {
  background-color: var(--gray2);
  border-radius: 4px;
  border: 1px solid transparent;
}
.lc-text-input.styled > input,
.lc-text-input.styled > textarea {
  background-color: var(--gray2);
}
.lc-text-input.styled[data-focused=true] {
  border: 1px solid var(--color-9);
  background-color: var(--lc-color-bg-1);
}
.lc-text-input.styled[data-focused=true] > input,
.lc-text-input.styled[data-focused=true] > textarea {
  background-color: var(--lc-color-bg-1);
}
