.lc-knowledge-source-item {
  display: flex;
  align-items: start;
}
.lc-knowledge-source-item svg {
  margin-right: 0.25rem;
  margin-top: 1px;
}
@media (max-width: 639px) {
  .lc-knowledge-source-item svg {
    margin-top: 0px;
  }
}
.lc-knowledge-source-item i {
  margin-right: 0.25rem;
  color: var(--gray11);
  font-size: 16px;
  margin-top: -1px;
}
.lc-knowledge-source-item .item-title {
  margin-top: -2px;
}
@media (max-width: 639px) {
  .lc-knowledge-source-item i {
    margin-right: 0.25rem;
    color: var(--gray11);
    font-size: 18px;
  }
  .lc-knowledge-source-item .item-title {
    margin-top: -4px;
  }
}
