.lc-grid {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.lc-grid .grid-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.lc-grid .grid-row .grid-cell {
  padding: var(--padding-size);
  flex-grow: 100;
}
.lc-grid .grid-row .grid-cell:first-child {
  padding-left: 0;
}
.lc-grid .grid-row .grid-cell:last-child {
  padding-right: 0;
}
.lc-grid .grid-row:first-child .grid-cell {
  padding-top: 0;
}
.lc-grid .grid-row:last-child .grid-cell {
  padding-bottom: 0;
}
