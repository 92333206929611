.bw-grid {
  display: flex;
  flex-wrap: wrap;
}
.bw-grid .col-4 {
  width: 33.333333%;
}
@media (min-width: 480px) and (max-width: 768px) {
  .bw-grid .col-4 {
    width: 50%;
  }
}
@media (max-width: 479px) {
  .bw-grid .col-4 {
    width: 100%;
  }
}
