.lc-text-area {
  border-radius: 2px;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background-color: inherit;
}
.lc-text-area textarea {
  flex-grow: 1;
  line-height: 1.5;
  border: none;
  resize: none;
  outline: none;
  background-color: inherit;
}
.lc-text-area.is-styled {
  background-color: var(--gray2);
  border: 1px solid transparent;
}
.lc-text-area.is-styled > input,
.lc-text-area.is-styled > textarea {
  background-color: var(--gray2);
}
.lc-text-area.is-styled[data-focused=true] {
  border: 1px solid var(--color-9);
  background-color: var(--lc-color-bg-1);
}
.lc-text-area.is-styled[data-focused=true] > input,
.lc-text-area.is-styled[data-focused=true] > textarea {
  background-color: var(--lc-color-bg-1);
}
