.lc-note-list-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}
.lc-note-list-container.used-in-drawer {
  width: 100%;
  padding: 1rem 0.5rem 1rem 0.25rem;
}
.lc-note-list-container > :not(:last-child) {
  margin-bottom: 0.125rem;
}
.lc-note-list-container .lc-menu-trigger {
  display: block;
  flex-grow: 1;
}
.lc-note-list-container .new-note {
  padding: 0 8px 6px 8px;
}
.lc-note-list-container .new-note .lc-button {
  width: 100%;
}
.lc-note-list-container .lc-sortable-tree-node {
  margin: 0 4px 0 4px;
}
@media (max-width: 639px) {
  .lc-note-list-container .lc-sortable-tree-node {
    padding: 0.25rem 0;
  }
}
.lc-note-list-container .note-summary {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 7px 4px 7px 2px;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  border-radius: 4px;
}
.lc-note-list-container .note-summary > :not(:last-child) {
  margin-right: 0.5rem;
}
.lc-note-list-container .note-summary .icon-and-title {
  display: flex;
  align-items: center;
}
.lc-note-list-container .note-summary .icon-and-title > :not(:last-child) {
  margin-right: 0.5rem;
}
.lc-note-list-container .note-summary .icon-and-title.untitled .lc-clamped-text {
  color: var(--gray11);
}
@media (min-width: 640px) {
  .lc-note-list-container .note-summary:hover > .op-icons {
    display: flex;
  }
}
.lc-note-list-container .note-summary svg {
  flex-shrink: 0;
  width: 14px;
  height: 14px;
}
.lc-note-list-container .note-summary .op-icons {
  display: flex;
}
@media (min-width: 640px) {
  .lc-note-list-container .note-summary .op-icons {
    display: none;
  }
}
.lc-note-list-container .note-summary.active {
  cursor: default;
}
