.lc-toggle-group {
  display: flex;
  background-color: var(--lc-color-bg-3);
  border-radius: 4px;
  padding: 2px;
  color: var(--gray11);
}
.lc-toggle-group .toggle-item {
  padding: 0.25rem 1rem;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  border-radius: 3px;
}
.lc-toggle-group .toggle-item[data-checked=true] {
  cursor: default;
  background-color: var(--color-3);
  color: var(--color-11);
}
.lc-toggle-group .toggle-item:not(:last-child) {
  border-right-width: 0.5px;
  border-color: var(--gray5);
}
