.one-page-layout-001 {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.one-page-layout-001 .nav-bar {
  width: 100%;
  z-index: 1;
}
.one-page-layout-001 .nav-bar .nav-item {
  cursor: pointer;
}
.one-page-layout-001 .nav-bar.fixed {
  position: fixed;
  top: 0;
  z-index: 21;
}
.one-page-layout-001 .section-div {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media screen and (min-device-width: 640px) {
  .one-page-layout-001 .section-div {
    min-height: 100%;
  }
}
@media screen and (max-device-width: 640px) {
  .one-page-layout-001 .section-div {
    min-height: 50%;
  }
}
