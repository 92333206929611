.lc-toast-viewport {
  --viewport-padding: 25px;
  padding: var(--viewport-padding);
  position: fixed;
  bottom: 0px;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  padding: 25px;
  gap: 10px;
  width: 390px;
  max-width: 100vw;
  margin: 0px;
  list-style: none;
  z-index: 2147483647;
  outline: none;
  font-size: 14px;
}
@media (max-width: 639px) {
  .lc-toast-viewport {
    font-size: 16px;
  }
}
.lc-toast-viewport .toast-root {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.8);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.lc-toast-viewport .toast-root > :not(:last-child) {
  margin-right: 0.75rem;
}
.lc-toast-viewport .toast-root[data-state='open'] {
  animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.lc-toast-viewport .toast-root[data-state='closed'] {
  animation: hide 100ms ease-in;
}
.lc-toast-viewport .toast-root[data-swipe='move'] {
  transform: translateX(var(--radix-toast-swipe-move-x));
}
.lc-toast-viewport .toast-root[data-swipe='cancel'] {
  transform: translateX(0);
  transition: transform 200ms ease-out;
}
.lc-toast-viewport .toast-root[data-swipe='end'] {
  animation: swipeOut 100ms ease-out;
}
.lc-toast-viewport .toast-root .undo-btn {
  background-color: #4a4a4a;
  color: white;
  padding: 0.25rem 0.75rem;
}
@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes slideIn {
  from {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
  to {
    transform: translateX(0);
  }
}
@keyframes swipeOut {
  from {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }
  to {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
}
