.lc-popover-2 {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 12px 0px;
  border-radius: 6px;
  background-color: var(--lc-color-bg-2);
  overflow: auto;
}
.lc-popover-2 .arrow {
  position: absolute;
  fill: var(--lc-color-bg-1);
  stroke-width: 1px;
  width: 12px;
  height: 12px;
}
.lc-popover-2 .arrow[data-placement=top] {
  top: 100%;
  transform: translateX(-50%);
}
.lc-popover-2 .arrow[data-placement=bottom] {
  bottom: 100%;
  transform: translateX(-50%) rotate(180deg);
}
.lc-popover-2 .arrow[data-placement=left] {
  left: 100%;
  transform: translateY(-50%) rotate(-90deg);
}
.lc-popover-2 .arrow[data-placement=right] {
  right: 100%;
  transform: translateY(-50%) rotate(90deg);
}
