@import '@radix-ui/colors/blackA.css';
@import '@radix-ui/colors/green.css';
@import '@radix-ui/colors/mauve.css';
@import '@radix-ui/colors/violet.css';
.lc-tabs {
  display: flex;
  flex-direction: column;
}
.lc-tabs .TabsList {
  flex-shrink: 0;
  display: flex;
  border-bottom: 1px solid var(--mauve6);
}
.lc-tabs .TabsTrigger {
  font-family: inherit;
  background-color: inherit;
  padding: 0 12px;
  height: 28px;
  margin: 6px 4px;
  flex-shrink: 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  color: var(--mauve11);
  user-select: none;
}
.lc-tabs .TabsTrigger[data-state='active'] {
  color: var(--violet11);
  position: relative;
}
.lc-tabs .TabsTrigger[data-state='active']::after {
  content: "";
  border-bottom: 2px solid currentColor;
  position: absolute;
  bottom: -6px;
  left: 0px;
  right: 0px;
}
.lc-tabs .TabsTrigger:hover {
  background-color: var(--mauve4);
}
.lc-tabs .TabsTrigger:focus {
  position: relative;
}
.lc-tabs .TabsContent {
  flex-grow: 1;
  padding: 20px 16px;
  height: calc(100% - var(--tab-bar-height));
  overflow: auto;
  outline: none;
}
