.lc-persona-card {
  padding: 1rem 1rem 0.75rem;
  box-shadow: var(--lc-shadow-xs);
  color: var(--gray11);
  border-radius: 4px;
  background-color: var(--lc-color-bg-3);
  height: 100%;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.lc-persona-card .title {
  color: var(--gray12);
  padding-right: 0.25rem;
}
.lc-persona-card .toolbar {
  position: absolute;
  top: 0px;
  right: 0px;
  display: none;
}
.lc-persona-card .toolbar > div {
  border-radius: 50%;
}
.lc-persona-card .toolbar > div:hover {
  cursor: pointer;
}
@media (max-width: 639px) {
  .lc-persona-card .toolbar {
    display: flex;
  }
}
.lc-persona-card:hover {
  box-shadow: var(--lc-shadow-base);
}
.lc-persona-card:hover .toolbar {
  display: flex;
}
.lc-add-persona-form {
  padding-bottom: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.lc-add-persona-form > .title {
  font-weight: 500;
  display: flex;
  align-items: center;
}
.lc-add-persona-form > .title i {
  font-size: 22px;
  padding-top: 2px;
  margin-right: 0.5rem;
}
@media (max-width: 639px) {
  .lc-add-persona-form > .title i {
    font-size: 24px;
  }
}
.lc-add-persona-form .template-select {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex-grow: 100;
}
.lc-add-persona-form .template-select .caption {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.lc-add-persona-form .template-select .template-list {
  padding: 0.5rem 0;
}
.lc-persona-card-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: var(--lc-shadow-xs);
  border-radius: 4px;
  background-color: var(--lc-color-bg-3);
  color: var(--gray11);
  height: 100%;
  min-height: 96px;
}
.lc-persona-card-placeholder:hover {
  box-shadow: var(--lc-shadow-base);
}
