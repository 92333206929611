.lc-drawer {
  height: 0 !important;
}
.lc-drawer[data-state="closed"] .drawer-overlay {
  display: none;
}
.lc-drawer[data-state="closed"] .drawer-content {
  visibility: hidden;
}
.lc-drawer[data-state="closed"] .drawer-content[data-position="right"] {
  transform: translate3d(100%, 0px, 0px);
}
.lc-drawer[data-state="closed"] .drawer-content[data-position="left"] {
  transform: translate3d(-100%, 0px, 0px);
}
.lc-drawer[data-state="open"] .drawer-overlay {
  display: block;
}
.lc-drawer[data-state="open"] .drawer-content {
  visibility: visible;
  transform: translate(0) !important;
}
.lc-drawer .drawer-overlay {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: #000000;
  opacity: 0.3;
}
.lc-drawer .drawer-content {
  transition-duration: 240ms;
  box-shadow: 0 0 10px 5px #0000001a;
  z-index: 1;
  position: fixed;
  top: 0px;
  width: 250px;
  height: 100vh;
  background-color: var(--lc-color-bg-2);
}
.lc-drawer .drawer-content[data-position="right"] {
  right: 0px;
}
.lc-drawer .drawer-content[data-position="left"] {
  left: 0px;
}
.lc-drawer [data-radix-popper-content-wrapper] {
  z-index: 1 !important;
}
