.lc-out-of-quota-tip {
  padding: 0.5rem;
  font-size: 13px;
  color: var(--gray11);
}
.lc-out-of-quota-tip i {
  font-size: 15px;
  opacity: 0.9;
  transform: translateY(1.75px);
  margin-right: 0.25rem;
}
@media (max-width: 639px) {
  .lc-out-of-quota-tip {
    font-size: 15px;
  }
  .lc-out-of-quota-tip i {
    font-size: 17px;
  }
}
.lc-out-of-quota-tip .pay-btn {
  color: var(--violet11) !important;
  font-weight: 500;
}
