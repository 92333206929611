.scroll-fade-pages-container {
  width: 100%;
  flex-shrink: 0;
}
.scroll-fade-pages-container .scroll-fade-pages-box {
  width: 100%;
  position: sticky;
  left: 0;
  top: 0;
}
.scroll-fade-pages-container .scroll-fade-pages-box .fade-page-item {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 0.5s;
}
.scroll-fade-pages-container .scroll-fade-pages-box .fade-page-item.show {
  opacity: 1;
}
@keyframes jump {
  0%,
  100% {
    transform: translate3D(0, 0, 0);
  }
  50% {
    transform: translate3D(0, 30%, 0);
  }
}
.scroll-fade-pages-container .arrow-down {
  width: 100%;
  height: 3rem;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  animation: jump 1.2s 0s infinite ease-in-out both;
  cursor: pointer;
}
