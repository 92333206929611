.loading-page-container {
  position: relative;
  height: 100%;
}
.loading-page-container .loading-page-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -100%);
  -ms-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
}
