.video-js button {
  outline: none;
}
.video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  background-size: cover;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E  %3Cg fill='none' fill-rule='evenodd'%3E    %3Ccircle cx='16' cy='16' r='16' fill='%23000' fill-opacity='.15'/%3E    %3Cpath fill='%23FFF' fill-rule='nonzero' d='M16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0zm0 1C7.716 1 1 7.716 1 16c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15zm-3.2 9.179a.8.8 0 0 1 .397.105l8.787 5.021a.8.8 0 0 1 0 1.39l-8.787 5.021A.8.8 0 0 1 12 21.021V10.98a.8.8 0 0 1 .8-.8z'/%3E  %3C/g%3E%3C/svg%3E");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border: none;
  box-shadow: none;
}
.video-js .vjs-big-play-button .vjs-icon-placeholder {
  line-height: 2em;
}
.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  content: "";
  display: none;
}
.video-js .vjs-poster {
  background-color: transparent;
}
.video-js:hover.vjs-big-play-button {
  background-color: transparent;
}
.video-js .vjs-control-bar .vjs-icon-placeholder::before {
  display: flex;
  justify-content: center;
  align-items: center;
}
.video-js .vjs-control-bar .vjs-play-control .vjs-icon-placeholder,
.video-js .vjs-control-bar .vjs-mute-control .vjs-icon-placeholder,
.video-js .vjs-control-bar .vjs-fullscreen-control .vjs-icon-placeholder {
  color: #FFF;
}
.video-js .vjs-control-bar .vjs-remaining-time {
  color: #FFF;
}
