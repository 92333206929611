.lc-button {
  white-space: pre;
  padding: 0 1rem;
  --button-height: 2rem;
  font-size: 14px;
  height: var(--button-height);
  min-height: var(--button-height);
  border-radius: 4px;
  position: relative;
  box-sizing: border-box;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lc-button:focus {
  box-shadow: 0 0 0 2px var(--color-7);
}
.lc-button .bx {
  font-size: 18px;
}
.lc-button[data-appearance="default"] {
  background-color: var(--color-3);
  color: var(--color-11);
}
.lc-button[data-appearance="default"]:hover:not(:disabled):not([data-loading=true]) {
  background-color: var(--color-4);
}
.lc-button[data-appearance="default"]:active:not(:disabled):not([data-loading=true]) {
  background-color: var(--color-5);
}
.lc-button[data-appearance="primary"] {
  background-color: var(--color-9);
  color: #fff;
}
.lc-button[data-appearance="primary"]:active:not(:disabled):not([data-loading=true]) {
  background-color: var(--color-10);
}
.lc-button[data-appearance="primary"][data-color="sky"],
.lc-button[data-appearance="primary"][data-color="mint"],
.lc-button[data-appearance="primary"][data-color="lime"],
.lc-button[data-appearance="primary"][data-color="yellow"] {
  color: #171717;
}
.lc-button[data-appearance="primary"][data-color="gray"],
.lc-button[data-appearance="primary"][data-color="mauve"],
.lc-button[data-appearance="primary"][data-color="slate"],
.lc-button[data-appearance="primary"][data-color="sage"],
.lc-button[data-appearance="primary"][data-color="olive"],
.lc-button[data-appearance="primary"][data-color="sand"] {
  background-color: var(--color-10);
}
.lc-button[data-appearance="primary"][data-color="gray"]:active:not(:disabled):not([data-loading=true]),
.lc-button[data-appearance="primary"][data-color="mauve"]:active:not(:disabled):not([data-loading=true]),
.lc-button[data-appearance="primary"][data-color="slate"]:active:not(:disabled):not([data-loading=true]),
.lc-button[data-appearance="primary"][data-color="sage"]:active:not(:disabled):not([data-loading=true]),
.lc-button[data-appearance="primary"][data-color="olive"]:active:not(:disabled):not([data-loading=true]),
.lc-button[data-appearance="primary"][data-color="sand"]:active:not(:disabled):not([data-loading=true]) {
  background-color: var(--color-11);
}
.lc-button[data-appearance="outline"] {
  background-color: var(--color-1);
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-6);
  color: var(--color-11);
}
.lc-button[data-appearance="outline"]:hover:not(:disabled):not([data-loading=true]) {
  border-color: var(--color-7);
}
.lc-button[data-appearance="outline"]:active:not(:disabled):not([data-loading=true]) {
  border-color: var(--color-8);
  background-color: var(--color-2);
}
.lc-button[data-loading=true]:before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 1;
  display: block;
  background: #fff;
  border-radius: inherit;
  opacity: 0.4;
  transition: opacity 0.1s linear;
  pointer-events: none;
}
@media (max-width: 639px) {
  .lc-button {
    --button-height: 2.25rem;
    font-size: 16px;
  }
}
.lc-button[data-size="mini"] {
  --button-height: 1.5rem;
  font-size: 12px;
}
@media (max-width: 639px) {
  .lc-button[data-size="mini"] {
    --button-height: 1.75rem;
    font-size: 14px;
  }
}
.lc-button[data-size="small"] {
  --button-height: 1.75rem;
  font-size: 13px;
}
@media (max-width: 639px) {
  .lc-button[data-size="small"] {
    --button-height: 2rem;
    font-size: 15px;
  }
}
.lc-button[data-size="large"] {
  --button-height: 2.25rem;
  font-size: 14px;
}
@media (max-width: 639px) {
  .lc-button[data-size="large"] {
    --button-height: 2.5rem;
    font-size: 16px;
  }
}
.lc-button[data-size="xlarge"] {
  --button-height: 2.5rem;
  font-size: 15px;
}
@media (max-width: 639px) {
  .lc-button[data-size="xlarge"] {
    --button-height: 2.75rem;
    font-size: 17px;
  }
}
.lc-button:disabled:not([data-loading=true]) {
  opacity: 0.6;
}
.lc-button:disabled:before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 1;
  display: block;
  background: #fff;
  border-radius: inherit;
  opacity: 0.3;
  transition: opacity 0.1s linear;
  pointer-events: none;
}
.lc-button:not(:disabled):not([data-loading=true]) {
  cursor: pointer;
}
.lc-button:disabled {
  cursor: not-allowed;
}
.lc-button[data-loading=true] {
  cursor: progress;
}
.lc-icon-button {
  all: unset;
  font-family: inherit;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--color-11);
  position: absolute;
  top: 10px;
  right: 10px;
}
.lc-icon-button:disabled {
  cursor: not-allowed;
}
.lc-icon-button:not(:disabled):not([data-loading=true]) {
  cursor: pointer;
}
.lc-icon-button:hover:not(:disabled) {
  background-color: var(--color-4);
}
