.lc-page-url-input {
  border: none;
  outline: none;
  box-shadow: none;
  resize: none;
  width: 100%;
  padding: 0 0.5rem;
  -webkit-tap-highlight-color: transparent;
  font-size: 14px;
}
@media (max-width: 639px) {
  .lc-page-url-input {
    font-size: 16px;
  }
}
