@import '@radix-ui/colors/blackA.css';
@import '@radix-ui/colors/green.css';
@import '@radix-ui/colors/mauve.css';
@import '@radix-ui/colors/violet.css';
.lc-dialog-overlay {
  background-color: var(--blackA9);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 100;
}
.lc-dialog-content {
  /* reset */
  z-index: 100;
  background-color: var(--lc-color-bg-2);
  border-radius: 6px;
  box-shadow: hsla(206, 22%, 7%, 0.35) 0px 10px 38px -10px, hsla(206, 22%, 7%, 0.2) 0px 10px 20px -15px;
  position: fixed;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 450px;
  max-height: 85vh;
  padding: 25px 0 1rem;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}
.lc-dialog-content .content-wrapper {
  flex-grow: 1;
  padding: 0 1.5rem;
  height: 100%;
  width: 100%;
  overflow: auto;
}
@media (max-width: 639px) {
  .lc-dialog-content {
    font-size: 16px;
  }
  .lc-dialog-content .content-wrapper {
    padding: 0 1rem;
  }
}
@media (max-width: 639px) {
  .lc-dialog-content[data-main=true] {
    font-size: 16px;
    max-width: none;
    padding: 2.5rem 0 0;
    width: 100vw;
    /* top: 100%; */
    top: 54%;
    height: 92vh;
    max-height: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}
.lc-dialog-content .DialogTitle {
  margin: 0;
  font-weight: 500;
  color: var(--mauve12);
  line-height: normal;
}
.lc-dialog-content .DialogDescription {
  margin: 10px 0 20px;
  color: var(--mauve11);
  font-size: 15px;
  line-height: 1.5;
}
.lc-dialog-content .Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  height: 35px;
}
.lc-dialog-content .Button.violet {
  background-color: white;
  color: var(--violet11);
  box-shadow: 0 2px 10px var(--blackA7);
}
.lc-dialog-content .Button.violet:hover {
  background-color: var(--mauve3);
}
.lc-dialog-content .Button.green {
  background-color: var(--green4);
  color: var(--green11);
}
.lc-dialog-content .Button.green:disabled {
  background-color: var(--mauve3);
  color: var(--mauve8);
}
.lc-dialog-content .Button.green:hover {
  background-color: var(--green5);
}
.lc-dialog-content .Button.green:hover:disabled {
  background-color: var(--mauve3);
}
.lc-dialog-content .Button.green:focus {
  box-shadow: 0 0 0 2px var(--green7);
}
.lc-dialog-content .IconButton {
  all: unset;
  font-family: inherit;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--mauve11);
  position: absolute;
  top: 10px;
  right: 10px;
}
.lc-dialog-content .IconButton:hover {
  background-color: var(--mauve4);
}
.lc-dialog-content .Fieldset {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 0;
}
.lc-dialog-content .Label {
  font-size: 15px;
  color: var(--mauve11);
  width: 90px;
  text-align: right;
  margin-right: 16px;
}
.lc-dialog-content .Input {
  all: unset;
  width: 100%;
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 15px;
  line-height: 1;
  color: var(--mauve11);
  box-shadow: 0 0 0 1px var(--mauve7);
  height: 35px;
}
.lc-dialog-content .Input:focus {
  box-shadow: 0 0 0 1.2px var(--mauve8);
}
@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
.lc-dialog-content:focus {
  outline: none;
}
.lc-dialog-confirm {
  padding-top: 1.5rem;
}
.lc-dialog-confirm textarea {
  background-color: inherit;
}
.lc-dialog-confirm > :not(:last-child) {
  margin-bottom: 0.5rem;
}
.lc-dialog-confirm .error-msg {
  color: var(--red11);
  font-size: small;
}
.lc-dialog-confirm .action-group {
  display: flex;
  justify-content: end;
  padding: 1rem 0 0.125rem;
}
.lc-dialog-confirm .action-group > :not(:last-child) {
  margin-right: 0.5rem;
}
.lc-dialog-confirm .action-group > button {
  padding: 0 1.5rem;
}
