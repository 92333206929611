@import "@radix-ui/colors/tomato.css";
.auth--confirm-token {
  padding: 2.5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.auth--confirm-token > :not(:last-child) {
  margin-bottom: 2rem;
}
.auth--confirm-token button {
  max-width: 30rem;
  width: 90%;
}
.auth--confirm-token .tip {
  font-size: 0.875rem;
  opacity: 0.7;
  align-self: center;
  text-align: center;
}
