.lc-progress-bar {
  width: 100%;
}
.lc-progress-bar[data-done=true] .ProgressIndicator {
  background-color: var(--color-7) !important;
}
.lc-progress-bar .ProgressRoot {
  position: relative;
  overflow: hidden;
  background: var(--color-4);
  border-radius: 99999px;
  width: 100%;
  height: 10px;
  /* Fix overflow clipping in Safari */
  /* https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0 */
  transform: translateZ(0);
}
.lc-progress-bar .ProgressRoot:hover {
  background: var(--color-5);
}
.lc-progress-bar .ProgressRoot:hover .ProgressIndicator {
  background-color: var(--color-10);
}
.lc-progress-bar .ProgressRoot .ProgressIndicator {
  background-color: var(--color-9);
  width: 100%;
  height: 100%;
  transition: transform 660ms cubic-bezier(0.65, 0, 0.35, 1);
}
.lc-progress-circle .circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: radial-gradient(closest-side, var(--lc-color-bg-1) 77%, transparent 80% 100%), conic-gradient(var(--color-9) var(--progress-value), var(--color-4) 0);
}
.lc-progress-circle.show-number .circle::before {
  content: var(--progress-value-str);
  font-size: 55%;
  font-weight: 500;
  color: var(--color-11);
}
