.lc-test-uikit {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: white;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 1.5rem;
}
.lc-test-uikit .section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}
.lc-test-uikit .section .title {
  font-size: 0.8em;
  color: var(--gray11);
  font-weight: 500;
}
