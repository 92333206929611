.lc-file-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
}
.lc-file-line {
  display: flex;
  gap: 0.25rem;
  padding: 0 0.25rem;
}
.lc-file-line i {
  padding-top: 3px;
}
@media (max-width: 639px) {
  .lc-file-line i {
    padding-top: 4px;
  }
}
.lc-file-line:hover {
  cursor: pointer;
}
.lc-file-line:hover .file-title {
  text-decoration: underline;
}
.lc-file-line .placeholder {
  cursor: default;
  color: var(--gray8);
  display: flex;
  gap: 0.25rem;
}
