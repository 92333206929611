.dynamic-logo-box {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.dynamic-logo-box .dynamic-logo {
  background-image: url(./components/images/rongchuan-logo.png);
  background-repeat: no-repeat;
  width: 100%;
  padding-top: 100%;
  animation: logoChange 4s 1s steps(97) 1 both;
}
@media (min-width: 640px) {
  .dynamic-logo-box .dynamic-logo {
    background-size: 100%;
  }
}
@media (max-width: 639px) {
  .dynamic-logo-box .dynamic-logo {
    background-size: 80%;
  }
}
@media (min-width: 640px) {
  @keyframes logoChange {
    0% {
      background-position: 0px 0px;
    }
    100% {
      background-position: 0px -24250px;
    }
  }
}
@media (max-width: 639px) {
  @keyframes logoChange {
    0% {
      background-position: center 0px;
    }
    100% {
      background-position: center -13580px;
    }
  }
}
