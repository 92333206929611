.wx-work-message {
  width: 100%;
}
.wx-work-message .content-text {
  line-height: 1.5em;
  white-space: pre-wrap;
  word-break: break-word;
  text-align: justify;
}
.wx-work-message .message-link {
  border-radius: 4px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  padding: 10px;
  cursor: pointer;
}
.wx-work-message .message-link .link-info {
  display: flex;
  width: 100%;
  margin-top: 10px;
  align-items: flex-start;
}
.wx-work-message .message-link .link-info .link-desc {
  font-size: 0.875em;
  color: #666;
  word-break: break-word;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  display: -webkit-inline-box;
}
.wx-work-message .message-link .link-info .link-pic {
  width: 48px;
  height: 48px;
}
.wx-work-message .message-link .link-info .link-cot {
  width: 40px;
  margin-left: 20px;
  height: 40px;
  background-color: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wx-work-message .attachment-doc {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.wx-work-message .attachment-doc .attachment-doc-icon {
  width: 30px;
  height: 30px;
  background-position: center;
  background-size: cover;
}
.wx-work-message .attachment-doc .attachment-doc-icon.doc,
.wx-work-message .attachment-doc .attachment-doc-icon.docx {
  background-image: url(./components/images/doc.png);
}
.wx-work-message .attachment-doc .attachment-doc-icon.pdf {
  background-image: url(./components/images/pdf.png);
}
.wx-work-message .attachment-doc .attachment-doc-icon.ppt,
.wx-work-message .attachment-doc .attachment-doc-icon.pptx {
  background-image: url(./components/images/ppt.png);
}
.wx-work-message .attachment-doc .attachment-doc-icon.xls,
.wx-work-message .attachment-doc .attachment-doc-icon.xlsx {
  background-image: url(./components/images/xls.png);
}
.wx-work-message .attachment-doc .attachment-doc-icon.unknown-doc-type {
  background-image: url(./components/images/doc_default.png);
}
.wx-work-message .attachment-doc .attachment-doc-title {
  flex: 1;
  font-size: 0.875em;
  margin-left: 10px;
  word-break: break-word;
}
.wx-work-message .message-audio audio {
  max-width: 100%;
}
.wx-work-message .message-audio .trumpet-audio {
  display: flex;
  align-items: center;
}
.wx-work-message .message-audio .trumpet-audio .trumpet-cot {
  position: relative;
  height: 36px;
  min-width: 72px;
  border: 0.5px solid #E0E0E0;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
}
.wx-work-message .message-audio .trumpet-audio .trumpet-cot .audio-play {
  background-repeat: no-repeat;
  background-image: url(./components/images/voice_play.png);
  background-size: 30px auto;
  height: 50px;
  animation: voiceplay 1.2s infinite step-end;
}
@keyframes voiceplay {
  0%,
  100% {
    background-position: -4px 2px;
  }
  33.333333% {
    background-position: -2px -28px;
  }
  66.666666% {
    background-position: 0px -58px;
  }
}
.wx-work-message .message-audio .trumpet-audio .trumpet-cot .trumpet {
  background-image: url(./components/images/trumpet.png);
  background-repeat: no-repeat;
  background-size: 10px auto;
  margin-left: 10px;
  height: 30px;
  background-position: 0px 10px;
}
.wx-work-message .message-audio .trumpet-audio .trumpet-cot .triangle,
.wx-work-message .message-audio .trumpet-audio .trumpet-cot .triangle-white {
  position: absolute;
  border-width: 6px;
  border-style: dashed solid dashed dashed;
  top: 50%;
  transform: translateY(-50%);
}
.wx-work-message .message-audio .trumpet-audio .trumpet-cot .triangle {
  left: -12px;
  border-color: transparent #e0e0e0 transparent transparent;
}
.wx-work-message .message-audio .trumpet-audio .trumpet-cot .triangle-white {
  left: -10.5px;
  border-color: transparent #ffffff transparent transparent;
}
.wx-work-message .message-audio .trumpet-audio .audio-duration {
  display: inline-block;
  width: 24px;
  font-size: 0.875em;
  color: #999;
  margin-left: 6px;
}
