.paged-data-list .pagination {
  background-color: white;
  width: 100%;
  display: flex;
  align-items: center;
}
@media (min-width: 640px) {
  .paged-data-list .pagination {
    padding: 0.5rem;
  }
}
.paged-data-list .pagination .pc-pagination {
  display: flex;
}
.paged-data-list .pagination .pc-pagination > .page-button:not(:last-child) {
  border-right: none;
}
.paged-data-list .pagination .pc-pagination > .page-button {
  padding: 6px 12px;
  text-decoration: none;
  color: #999;
  background-color: #fff;
  border: 1px solid #F2F2F2;
  line-height: 24px;
  cursor: pointer;
}
.paged-data-list .pagination .pc-pagination > .page-button.active,
.paged-data-list .pagination .pc-pagination > .page-button:hover {
  color: #1b4060;
  background-color: #FAFAFA;
  border-color: #F2F2F2;
}
.paged-data-list .pagination .pc-pagination > .page-button.disabled {
  color: #DDDDDD;
  background-color: #fff;
  border-color: #F2F2F2;
  cursor: not-allowed;
}
.paged-data-list .pagination .mobile-pagination-container {
  width: 100%;
  padding: 0 8px;
  background-color: #F2F2F2;
}
.paged-data-list .pagination input,
.paged-data-list .pagination button {
  border-width: 1px;
  font-size: 12px;
  border-color: rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  margin-right: 1rem;
  padding: 2px 8px;
}
.paged-data-list .pagination input {
  width: 4rem;
}
.paged-data-list .pagination.pagination-fixed {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}
.paged-data-list .page-list-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.paged-data-list .page-list-footer {
  width: 100%;
  padding: 60px 0;
  text-align: center;
  font-size: 12px;
  color: #999;
}
.paged-data-list .page-list-footer .page-list-footer-line {
  display: inline-block;
  height: 1px;
  width: 34%;
  vertical-align: middle;
  background-color: #F2F2F2;
}
.paged-data-list .page-list-footer .page-list-footer-line:first-child {
  margin-right: 10px;
}
.paged-data-list .page-list-footer .page-list-footer-line:last-child {
  margin-left: 10px;
}
