.lc-record-form {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
.lc-record-form .field-list {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
.lc-record-form .field-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.lc-record-form .field-item .label {
  font-weight: 500;
  color: var(--gray11);
}
.lc-record-form .field-item .input {
  display: flex;
  flex-direction: column;
}
.lc-record-form .field-item .lc-text-input,
.lc-record-form .field-item .lc-text-area {
  background-color: var(--gray2);
  border: 1px solid transparent;
}
.lc-record-form .field-item .lc-text-input > input,
.lc-record-form .field-item .lc-text-area > input,
.lc-record-form .field-item .lc-text-input > textarea,
.lc-record-form .field-item .lc-text-area > textarea {
  background-color: var(--gray2);
}
.lc-record-form .field-item .lc-text-input[data-focused=true],
.lc-record-form .field-item .lc-text-area[data-focused=true] {
  border: 1px solid var(--color-9);
  background-color: var(--lc-color-bg-1);
}
.lc-record-form .field-item .lc-text-input[data-focused=true] > input,
.lc-record-form .field-item .lc-text-area[data-focused=true] > input,
.lc-record-form .field-item .lc-text-input[data-focused=true] > textarea,
.lc-record-form .field-item .lc-text-area[data-focused=true] > textarea {
  background-color: var(--lc-color-bg-1);
}
.lc-record-form .error {
  color: var(--tomato9);
  padding-left: 2px;
}
.lc-record-form .action-area {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  padding: 1.5rem 0 0.5rem;
}
.lc-record-form .action-area .lc-button {
  padding: 0 2rem;
}
