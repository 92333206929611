@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}
.lc-loading-cursor::after {
  content: "";
  width: 1px;
  height: 1em;
  background: var(--gray12);
  display: inline-block;
  animation: cursor-blink 1.1s steps(2) infinite;
  transform: translateY(2px);
}
