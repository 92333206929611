.rc-navigation-container {
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rc-navigation-container .toggle-nav-icon-container {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  pointer-events: auto;
}
@media (min-width: 640px) {
  .rc-navigation-container .toggle-nav-icon-container {
    width: 2.5rem;
    height: 27px;
  }
}
@media (max-width: 639px) {
  .rc-navigation-container .toggle-nav-icon-container {
    width: 1.5rem;
    height: 1.5rem;
  }
}
.rc-navigation-container .toggle-nav-icon-container .navicon-bar {
  position: absolute;
  left: 0;
  right: 0;
  transform-origin: 12% 50%;
  -webkit-transform-origin: 12% 50%;
  transition: all 0.3s ease;
}
@media (min-width: 640px) {
  .rc-navigation-container .toggle-nav-icon-container .navicon-bar {
    height: 3px;
  }
  .rc-navigation-container .toggle-nav-icon-container .navicon-bar:nth-child(2) {
    top: 12px;
  }
  .rc-navigation-container .toggle-nav-icon-container .navicon-bar:nth-child(3) {
    top: 24px;
  }
}
@media (max-width: 639px) {
  .rc-navigation-container .toggle-nav-icon-container .navicon-bar {
    height: 2px;
  }
  .rc-navigation-container .toggle-nav-icon-container .navicon-bar:nth-child(2) {
    top: 8px;
  }
  .rc-navigation-container .toggle-nav-icon-container .navicon-bar:nth-child(3) {
    top: 15px;
  }
}
.rc-navigation-container .toggle-nav-icon-container.show-nav .navicon-bar:nth-child(1) {
  transform: rotate(45deg) translate(4%, 0);
}
.rc-navigation-container .toggle-nav-icon-container.show-nav .navicon-bar:nth-child(2) {
  display: none;
}
.rc-navigation-container .toggle-nav-icon-container.show-nav .navicon-bar:nth-child(3) {
  transform: rotate(-45deg) translate(4%, 0);
}
.rc-navigation-container .rongchuan-logo-box {
  cursor: pointer;
  pointer-events: auto;
}
.rc-navigation-container .rongchuan-logo-box .colored-path {
  transition: all 0.3s ease;
}
