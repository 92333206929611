.lc-avatar {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--size-if-narrow);
  width: var(--size-if-narrow);
  min-width: var(--size-if-narrow);
  font-size: var(--size-if-narrow);
}
@media (min-width: 640px) {
  .lc-avatar {
    height: var(--size-if-wide);
    width: var(--size-if-wide);
    min-width: var(--size-if-wide);
    font-size: var(--size-if-wide);
  }
}
