.lc-task-job-list {
  padding: 0.5rem 0 0;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background-color: var(--gray2);
}
.lc-task-job-list .empty-state {
  height: 60%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
}
.lc-task-job-list .empty-state i {
  font-size: 48px;
  color: var(--gray8);
}
.lc-task-job-list .empty-state .empty-state-tip {
  color: var(--gray10);
}
.lc-task-job-list .job-list-head {
  padding: 0.5rem 1.5rem;
  display: flex;
  align-items: center;
}
.lc-task-job-list .job-list {
  display: flex;
  flex-direction: column;
  padding: 0.125rem 1rem 0.5rem;
  flex-grow: 1;
  overflow: auto;
  gap: 1rem;
}
