.bw-tooltip {
  z-index: 1999;
  min-width: 32px;
  min-height: 32px;
  max-width: 30rem;
}
.bw-tooltip .content-wrapper {
  padding: 6px 12px;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-all;
  word-break: break-all;
  word-wrap: break-word;
  border-radius: 2px;
}
.dark-theme .bw-tooltip .content-wrapper {
  background-color: var(--lc-color-bg-3);
  color: var(--gray11);
}
