@media (min-width: 640px) {
  .rongchuan-logo {
    width: 64px;
    height: 64px;
  }
}
@media (max-width: 639px) {
  .rongchuan-logo {
    width: 40px;
    height: 40px;
  }
}
