.navigationBar-container {
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  z-index: 2;
}
@media (max-width: 639px) {
  .navigationBar-container.unfold {
    background-color: rgba(0, 0, 0, 0.32);
    height: 100%;
  }
}
.navigationBar-container .navigationBar-outer-box {
  width: 100%;
  background-color: #fff;
  display: flex;
  border-bottom: 1px solid #f0f3f5;
  position: relative;
}
@media (min-width: 640px) {
  .navigationBar-container .navigationBar-outer-box {
    justify-content: center;
    height: 4rem;
  }
}
@media (max-width: 639px) {
  .navigationBar-container .navigationBar-outer-box {
    height: 3rem;
  }
}
.navigationBar-container .navigationBar-outer-box .navigationBar-inner-box {
  width: 100%;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 640px) {
  .navigationBar-container .navigationBar-outer-box .navigationBar-inner-box {
    max-width: 64rem;
  }
}
.navigationBar-container .navigationBar-outer-box .navigationBar-inner-box .navs-box {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  height: 100%;
}
@media (min-width: 640px) {
  .navigationBar-container .navigationBar-outer-box .navigationBar-inner-box .navs-box {
    margin-right: 1.5rem;
  }
}
@media (max-width: 639px) {
  .navigationBar-container .navigationBar-outer-box .navigationBar-inner-box .navs-box {
    display: none;
  }
}
.navigationBar-container .navigationBar-outer-box .navigationBar-inner-box .navigation-action {
  display: flex;
}
.navigationBar-container .navigationBar-outer-box .navigationBar-inner-box .navigation-action > div {
  margin-right: 0.5rem;
}
.navigationBar-container .navigationBar-outer-box .navs-dropDown {
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
  z-index: 20;
  margin: 0 !important;
  pointer-events: none;
  border-top: 1px solid #f0f3f5;
}
@media (min-width: 640px) {
  .navigationBar-container .navigationBar-outer-box .navs-dropDown {
    display: none!important;
  }
}
