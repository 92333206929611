.lc-layout-menu-and-main {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}
.lc-layout-menu-and-main .menu-part {
  width: 200px;
}
.lc-layout-menu-and-main[data-menu="left"] {
  flex-direction: row-reverse;
}
.lc-layout-menu-and-main[data-menu="right"] > .resize-line {
  border-left: none;
  border-right: 1px solid var(--gray4);
}
.lc-layout-menu-and-main > .resize-line {
  border-left: 1px solid var(--gray4);
  height: 100%;
  cursor: col-resize;
  position: relative;
}
.lc-layout-menu-and-main > .resize-line::before {
  content: "";
  height: 100%;
  width: 7px;
  position: absolute;
  left: -3px;
}
.lc-layout-menu-and-main .menu-drawer {
  width: 85vw !important;
}
.lc-layout-menu-and-main .main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1000;
  min-width: 0;
  flex-basis: 0%;
  margin: 0 auto;
}
@media (max-width: 639px) {
  .lc-layout-menu-and-main .main-content {
    padding-top: 48px;
  }
}
