.editor-brick-toolbar {
  position: absolute;
  left: 0px;
  cursor: pointer;
  transition: 0.2s;
}
.editor-brick-toolbar .editor-brick-toolbar-trigger {
  display: flex;
  color: var(--gray11);
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
}
.editor-brick-toolbar .editor-brick-toolbar-trigger:hover {
  background-color: var(--gray2);
}
.brick-toolbar-sub-trigger[data-state='open'],
.brick-toolbar-sub-trigger[data-highlighted] {
  background-color: var(--gray3);
}
.brick-toolbar-sub-trigger .arrow {
  margin-left: auto;
}
.brick-toolbar-menu-item[data-highlighted] {
  background-color: var(--gray3);
}
.brick-toolbar-subMenu-item[data-highlighted] {
  background-color: var(--gray3);
}
