.lc-knowledge-options-input {
  min-width: 144px;
}
.lc-knowledge-options-input .badge {
  pointer-events: auto;
  cursor: pointer;
}
.lc-knowlege-enabled {
  color: var(--grass11);
}
.lc-knowlege-limited {
  color: var(--violet11);
}
.lc-knowlege-limited .badge {
  background-color: var(--violet5);
  margin-left: 4px;
  padding: 2px 4px;
  display: inline-flex;
  border-radius: 50%;
  font-size: 12px;
  line-height: 12px;
}
.lc-knowlege-disabled {
  color: var(--gray11);
}
