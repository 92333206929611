.lc-layout-base > .side-menu:hover + .resize-line[data-dragging=false] .collapse-button {
  visibility: visible;
}
.lc-layout-base > .resize-line {
  border-left: 1px solid var(--gray6);
  height: 100%;
  position: relative;
}
.lc-layout-base > .resize-line::before {
  content: "";
  height: 100%;
  width: 7px;
  position: absolute;
  left: -4px;
}
.lc-layout-base > .resize-line[data-collapsed=false] {
  cursor: col-resize;
}
.lc-layout-base > .resize-line:hover[data-dragging=false] .collapse-button {
  visibility: visible;
}
.lc-layout-base > .resize-line .collapse-button {
  position: absolute;
  top: 48vh;
  left: -0.5rem;
  box-shadow: var(--lc-shadow-base);
  background-color: var(--lc-color-bg-3);
  display: flex;
  align-items: center;
  padding: 0.75rem 0;
  border-radius: 9999px;
  font-size: 14px;
  color: var(--gray11);
  cursor: pointer;
  visibility: hidden;
  transition: all 0.3s ease-out;
}
.lc-layout-base > .resize-line .collapse-button:hover {
  box-shadow: var(--lc-shadow-md);
}
@media (max-width: 639px) {
  .lc-layout-base .main-area {
    width: 100%;
  }
}
@media (min-width: 640px) {
  .lc-layout-base .main-area {
    width: calc(100% - var(--menu-width));
  }
}
.lc-layout-base .main-area .top-menu {
  padding: 0.5rem 1rem;
  position: fixed;
  width: 100%;
  background-color: var(--lc-color-bg-1);
  top: 0;
  border-bottom: 0.5px solid #e5e6eb;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 639px) {
  .lc-layout-base .main-area .top-menu {
    height: 48px;
  }
}
.lc-layout-base .main-area .top-menu svg {
  height: 24px;
  width: 24px;
  opacity: 0.7;
}
