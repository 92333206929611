.lc-knowledge-item-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.lc-knowledge-item-list .item-list {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.lc-knowledge-item-list .item-list .item-line {
  padding: 0.75rem 1.25rem 0.75rem 0.5rem;
  width: 100%;
  border-radius: 2px;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 0.375rem;
}
@media (max-width: 639px) {
  .lc-knowledge-item-list .item-list .item-line {
    padding: 1rem 1rem 1rem 0.25rem;
  }
}
.lc-knowledge-item-list .item-list .item-line > :first-child {
  flex-grow: 100;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
}
.lc-knowledge-item-list .item-list .item-line .main-part {
  display: flex;
  align-items: start;
}
.lc-knowledge-item-list .item-list .item-line .main-part .icon {
  margin-right: 0.5rem;
  font-size: 16px;
}
@media (max-width: 639px) {
  .lc-knowledge-item-list .item-list .item-line .main-part .icon {
    font-size: 18px;
  }
}
.lc-knowledge-item-list .item-list .item-line .info-part {
  white-space: pre;
  color: var(--gray11);
  font-size: 12px;
  transform: translateY(1px);
}
@media (max-width: 639px) {
  .lc-knowledge-item-list .item-list .item-line .info-part {
    font-size: 13px;
  }
}
@media (min-width: 640px) {
  .lc-knowledge-item-list .item-list .item-line:hover > .op-icons {
    display: flex;
  }
}
.lc-knowledge-item-list .item-list .item-line .op-icons {
  display: flex;
  padding-top: 0.125rem;
  position: absolute;
  right: 0.25rem;
  top: 13px;
  opacity: 0.6;
  cursor: pointer;
}
.lc-knowledge-item-list .item-list .item-line .op-icons svg {
  height: 14px;
}
@media (max-width: 639px) {
  .lc-knowledge-item-list .item-list .item-line .op-icons {
    top: 17px;
  }
}
