.lc-note-edit {
  width: 100%;
  padding: 64px 0.5rem 0;
  height: 100%;
  overflow: auto;
}
.lc-note-edit .note-edit-area {
  max-width: 45rem;
  margin: 0 auto;
}
.lc-note-edit .note-edit-area textarea {
  background-color: inherit;
}
.lc-note-edit .note-edit-area .rich-text > :last-child {
  margin-bottom: 2rem;
}
.lc-note-edit .note-edit-top-bar-cntr {
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  display: flex;
}
.lc-note-edit .note-edit-top-bar {
  position: absolute;
  top: 0;
  justify-content: space-between;
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid var(--lc-color-border);
  background-color: var(--lc-color-bg-1);
}
.lc-note-edit .note-edit-top-bar .action-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.lc-note-edit .note-edit-top-bar .action-group .more-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.lc-note-edit .note-edit-top-bar .lc-button {
  padding: 0 0.75rem;
}
.lc-note-edit .note-title-input {
  border: none;
  outline: none;
  box-shadow: none;
  resize: none;
  width: calc(100% - 2.5rem);
  /* padding: 0.25rem 0.75rem; */
  font-size: 32px;
  font-weight: 500;
  /* box-sizing: content-box; */
  margin: 0.25rem 0.5rem;
}
.lc-note-edit .note-title-input::placeholder {
  color: var(--gray8);
}
@media (min-width: 640px) {
  .lc-note-edit .note-title-input {
    margin: 0.25rem 19px;
  }
}
.lc-note-edit .slate-editor .slate-edit-area .editor-area-container > [contenteditable=true] {
  min-height: var(--note-edit-area-min-height) !important;
}
.lc-note-edit .status-bar {
  width: calc(100% - 1rem);
}
.lc-note-panel-resizer {
  border-right: 1px solid var(--gray4);
  height: 100%;
  cursor: col-resize;
  position: relative;
}
.lc-note-panel-resizer::before {
  content: "";
  height: 100%;
  width: 7px;
  position: absolute;
  left: -3px;
}
.lc-note-edit .rich-text.rich-text-v2 {
  white-space: pre-wrap;
}
.lc-note-edit .rich-text.rich-text-v2 > p:not(:last-child),
.lc-note-edit .rich-text.rich-text-v2 > div:not(:last-child) {
  margin-bottom: 12px;
}
.lc-note-edit .rich-text.rich-text-v2 > p:first-child,
.lc-note-edit .rich-text.rich-text-v2 > div:first-child {
  margin-top: 6px;
}
.lc-note-edit .rich-text.rich-text-v2 > p:last-child,
.lc-note-edit .rich-text.rich-text-v2 > div:last-child {
  margin-bottom: 6px;
}
.lc-note-edit .rich-text.rich-text-v2 > figure,
.lc-note-edit .rich-text.rich-text-v2 > pre,
.lc-note-edit .rich-text.rich-text-v2 > .table-wrapper,
.lc-note-edit .rich-text.rich-text-v2 > code {
  margin: 16px 0;
}
.lc-note-edit .rich-text.rich-text-v2 > figure:first-child,
.lc-note-edit .rich-text.rich-text-v2 > pre:first-child,
.lc-note-edit .rich-text.rich-text-v2 > .table-wrapper:first-child,
.lc-note-edit .rich-text.rich-text-v2 > code:first-child {
  margin-top: 6px;
}
.lc-note-edit .rich-text.rich-text-v2 > figure:last-child,
.lc-note-edit .rich-text.rich-text-v2 > pre:last-child,
.lc-note-edit .rich-text.rich-text-v2 > .table-wrapper:last-child,
.lc-note-edit .rich-text.rich-text-v2 > code:last-child {
  margin-bottom: 6px;
}
.lc-note-edit .rich-text.rich-text-v2 > figure.editor-not-support-element,
.lc-note-edit .rich-text.rich-text-v2 > pre.editor-not-support-element,
.lc-note-edit .rich-text.rich-text-v2 > .table-wrapper.editor-not-support-element,
.lc-note-edit .rich-text.rich-text-v2 > code.editor-not-support-element {
  padding: 0.5rem 0.5rem;
  background-color: var(--yellow4);
}
.lc-note-edit .rich-text.rich-text-v2 > blockquote:first-child {
  margin-top: 6px;
}
.lc-note-edit .rich-text.rich-text-v2 > blockquote:last-child {
  margin-bottom: 6px;
}
.lc-note-edit .rich-text.rich-text-v2 > blockquote.single-blockquote {
  margin: 16px 0;
}
.lc-note-edit .rich-text.rich-text-v2 > blockquote.first-blockquote {
  margin-top: 16px;
}
.lc-note-edit .rich-text.rich-text-v2 > blockquote + blockquote:not(.middle-blockquote) {
  margin-bottom: 16px;
}
.lc-note-edit .rich-text.rich-text-v2 > h2 {
  margin-top: 28px;
  margin-bottom: 18px;
}
.lc-note-edit .rich-text.rich-text-v2 > h2:first-child {
  margin-top: 9px;
}
.lc-note-edit .rich-text.rich-text-v2 > h3 {
  margin-top: 24px;
  margin-bottom: 16px;
}
.lc-note-edit .rich-text.rich-text-v2 > h3:first-child {
  margin-top: 9px;
}
.lc-note-edit .rich-text.rich-text-v2 > h4,
.lc-note-edit .rich-text.rich-text-v2 h5,
.lc-note-edit .rich-text.rich-text-v2 h6 {
  margin-top: 20px;
  margin-bottom: 16px;
}
.lc-note-edit .rich-text.rich-text-v2 > h4:first-child,
.lc-note-edit .rich-text.rich-text-v2 h5:first-child,
.lc-note-edit .rich-text.rich-text-v2 h6:first-child {
  margin-top: 9px;
}
.lc-note-edit .rich-text.rich-text-v2 > ul,
.lc-note-edit .rich-text.rich-text-v2 > ol {
  margin-top: 12px;
  margin-bottom: 12px;
}
.lc-note-edit .rich-text.rich-text-v2 > ul:first-child,
.lc-note-edit .rich-text.rich-text-v2 > ol:first-child {
  margin-top: 6px;
}
.lc-note-edit .rich-text.rich-text-v2 > ul:last-child,
.lc-note-edit .rich-text.rich-text-v2 > ol:last-child {
  margin-bottom: 6px;
}
.lc-note-edit .rich-text.rich-text-v2 li {
  margin-top: 0;
}
.lc-note-edit .rich-text.rich-text-v2 li:not(:last-child) {
  margin-bottom: 8px;
}
.lc-note-edit .rich-text.rich-text-v2 li p:not(:last-child) {
  margin-bottom: 8px;
}
.lc-note-edit .rich-text.rich-text-v2 li ul:not(:first-child),
.lc-note-edit .rich-text.rich-text-v2 li ol:not(:first-child) {
  margin: 8px 0;
}
.lc-note-edit .rich-text.rich-text-v2 .nest-wrapper li {
  margin-left: 2em;
}
