.lc-context-menu {
  min-width: 180px;
  background-color: var(--lc-color-bg-3);
  border-radius: 6px;
  overflow: hidden;
  padding: 7px 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  font-size: 13px;
}
@media (max-width: 639px) {
  .lc-context-menu {
    font-size: 15px;
  }
}
.lc-context-menu .context-menu-item {
  line-height: 1;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 5px 8px 5px 11px;
  position: relative;
  user-select: none;
  outline: none;
}
.lc-context-menu .context-menu-item:not(:last-child) {
  margin-bottom: 3px;
}
@media (max-width: 639px) {
  .lc-context-menu .context-menu-item {
    font-size: 16px;
    padding: 9px 8px 9px 11px;
  }
}
.lc-context-menu .context-menu-item[data-disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.lc-context-menu .context-menu-item:hover:not([data-disabled]) {
  background-color: var(--gray3);
}
.lc-context-menu .context-menu-item .icon {
  width: 1rem;
  margin-right: 0.5rem;
  font-size: 15px;
  opacity: 0.7;
  display: flex;
  align-items: center;
}
button.lc-menu-trigger {
  background: none;
}
