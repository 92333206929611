.lc-select-input {
  padding: 0.125rem 0.5rem;
  background-color: var(--lc-color-bg-3);
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  justify-content: space-between;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.lc-select-input .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--gray10);
}
.lc-select-input .current-value {
  padding: 0.25rem 0.5rem;
}
.lc-select-input .current-value .placeholder {
  color: var(--gray10);
}
.lc-select-popover {
  background-color: var(--lc-color-bg-3);
}
