.lc-knowledge-scope-dialog {
  max-width: 60rem;
}
@media (min-width: 640px) {
  .lc-knowledge-scope-dialog {
    height: 80vh;
    padding: 2.25rem 1.75rem;
  }
}
.lc-knowledge-scope-dialog .selection-panel {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.lc-knowledge-scope-dialog .selection-panel .selected-item {
  display: flex;
  align-items: start;
  gap: 1rem;
  cursor: pointer;
}
.lc-knowledge-scope-dialog .tab-container {
  padding: 0.25rem 0rem;
  height: calc(100% - 53px);
}
.lc-knowledge-scope-dialog .tab-container .lc-tabs {
  height: 100%;
}
.lc-knowledge-scope-dialog .tab-container .selected-tab {
  display: flex;
  align-items: center;
}
.lc-knowledge-scope-dialog .tab-container .badge {
  background-color: var(--mauve5);
  margin-left: 4px;
  padding: 2px 4px;
  display: inline-flex;
  border-radius: 50%;
  font-size: 12px;
}
.lc-knowledge-scope-dialog .tab-container [data-state="active"] .badge {
  background-color: var(--violet5);
}
.lc-knowledge-scope-dialog .search-input {
  padding: 0;
  margin-bottom: 1rem;
}
