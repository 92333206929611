.lc-ai-input-menus {
  width: 100%;
  padding: 0 0.5rem 0.5rem;
  --richtext-tool-box-shadow: rgba(0, 12, 28, 0.2) 0px 2px 8px;
}
@media (min-width: 640px) {
  .lc-ai-input-menus {
    position: absolute;
  }
}
.lc-ai-input-menus .in-progress-bar {
  flex-grow: 100;
  padding: 0 6px;
  min-height: 28px;
  display: flex;
  align-items: center;
}
.lc-ai-input-menus .in-progress-bar > :not(:last-child) {
  margin-right: 0.5rem;
}
.lc-ai-input-menus .generated-and-input-container {
  box-shadow: var(--richtext-tool-box-shadow);
  border-radius: 4px;
  position: relative;
  background-color: var(--lc-color-bg-3);
  padding: 6px;
}
.lc-ai-input-menus .generated-and-input-container .generated-display {
  width: 100%;
  overflow: hidden auto;
  white-space: pre-line;
}
@media (min-width: 640px) {
  .lc-ai-input-menus .generated-and-input-container .generated-display {
    max-height: min(40vh, 320px);
  }
}
@media (max-width: 639px) {
  .lc-ai-input-menus .generated-and-input-container .generated-display {
    max-height: 100px;
  }
}
.lc-ai-input-menus .generated-and-input-container .input-and-index-select {
  display: flex;
  align-items: flex-start;
}
.lc-ai-input-menus .generated-and-input-container .input-and-index-select textarea {
  resize: none;
  width: 100%;
  border-radius: 4px;
  position: relative;
  padding: 6px;
  border: none;
  display: block;
  font-size: 14px;
}
.lc-ai-input-menus .generated-and-input-container .input-and-index-select .responded-index-container {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  border-left: 1px solid var(--lc-color-border);
  color: rgba(55, 53, 47, 0.5);
  font-size: 14px;
  height: 21px;
  align-self: flex-end;
}
@media (min-width: 640px) {
  .lc-ai-input-menus .generated-and-input-container .input-and-index-select .responded-index-container {
    margin: 8px;
    padding-left: 8px;
  }
}
@media (max-width: 639px) {
  .lc-ai-input-menus .generated-and-input-container .input-and-index-select .responded-index-container {
    margin: 4px;
    padding-left: 4px;
  }
}
.lc-ai-input-menus .generated-and-input-container .input-and-index-select .responded-index-container .index-box {
  display: flex;
  padding: 2px;
}
.lc-ai-input-menus .generated-and-input-container .input-and-index-select .responded-index-container .index-box:not(.disabled):hover {
  background-color: rgba(55, 53, 47, 0.08);
  cursor: pointer;
  border-radius: 4px;
}
.lc-ai-input-menus .generated-and-input-container .input-and-index-select .responded-index-container .index-box.disabled {
  opacity: 0.4;
}
.lc-ai-input-menus .generated-and-input-container .input-and-index-select .responded-index-container span {
  margin: 0 4px;
}
.lc-discard-modal {
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lc-discard-modal .discard-tip-modal-backdrop {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.5);
}
.lc-discard-modal .discard-tip-box {
  position: relative;
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 5px 10px, rgba(15, 15, 15, 0.2) 0px 15px 40px;
  border-radius: 6px;
  background: white;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
.lc-discard-modal .discard-tip-box P {
  font-weight: 500;
  max-width: 240px;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 8px;
  line-height: 1.2em;
  font-size: 16px;
}
