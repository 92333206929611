.lc-checkbox {
  background-color: var(--lc-color-bg-7);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  --checkbox-size: 16px;
  width: var(--checkbox-size);
  height: var(--checkbox-size);
  min-height: var(--checkbox-size);
  min-width: var(--checkbox-size);
  border: 2px solid var(--gray4);
  border-radius: 2px;
}
@media (max-width: 639px) {
  .lc-checkbox {
    --checkbox-size: 18px;
  }
}
.lc-checkbox:hover {
  background-color: var(--gray3);
}
.lc-checkbox[data-state="checked"] {
  background-color: var(--color-11);
  border: 2px solid var(--color-11);
}
.lc-checkbox .CheckboxIndicator {
  height: 16px;
  color: #fff;
}
