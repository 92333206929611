.lc-usage-panel {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.lc-usage-panel img {
  width: 1em;
  height: 1em;
  margin-right: 0.5rem;
}
.lc-usage-panel .current-plan {
  display: flex;
  align-items: center;
}
.lc-usage-panel .info {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-left: 1.25rem;
}
.lc-usage-panel a {
  color: var(--violet11) !important;
  font-weight: 500;
}
