.not-found-page {
  width: 100%;
  height: 100%;
  text-align: center;
}
.not-found-page .cb-page-content {
  height: 100%;
}
.not-found-page .content {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.not-found-page .content .top-placeholder {
  height: 20%;
  width: 1px;
  display: block;
}
.not-found-page .logo-icon {
  width: 260px;
}
