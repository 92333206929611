.toolbar-content .toolbar-override {
  width: 300px;
}
.toolbar-content .toolbar-override input {
  outline: none;
  border: none;
}
.toolbar-content input {
  position: relative;
  display: inline-block;
  padding: 4px 7px;
  width: 100%;
  height: 28px;
  font-size: 12px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  background-image: none;
  border: 1px solid var(--lc-color-border);
  border-radius: 4px;
}
.toolbar-content .seperator {
  display: inline-block;
  vertical-align: middle;
  width: 1px;
  margin: 8px 6px;
  height: 15px;
  background: var(--lc-color-border);
}
.editor-button {
  font-size: 12px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  border: 0px solid transparent;
  height: 30px;
  outline: none;
  background-color: inherit;
  line-height: 15px;
}
.editor-button svg {
  height: 18px;
}
.editor-button.active {
  color: var(--blue11);
}
@media (min-width: 640px) {
  .editor-button:hover {
    background-color: var(--gray3);
    cursor: pointer;
  }
}
.editor-button:hover:disabled {
  background-color: unset;
}
.editor-button:disabled {
  color: var(--gray5);
}
.editor-button-group {
  display: flex;
}
.editor-toolbar .editor-button-group {
  flex-wrap: wrap;
}
.inline-toolbar {
  left: 50%;
  transform: translate(-50%) scale(0);
  position: absolute;
  border: 1px solid var(--lc-color-border);
  background: var(--lc-color-bg-2);
  border-radius: 3px;
  padding: 2px 0;
  box-shadow: 0px 1px 2px 0px var(--lc-color-border);
  box-sizing: border-box;
}
.inline-toolbar:not(.no-arrow):after,
.inline-toolbar:not(.no-arrow):before {
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.inline-toolbar.up::after {
  display: none;
}
.inline-toolbar.bottom::before {
  display: none;
}
.inline-toolbar:not(.no-arrow):after {
  top: 100%;
  border-color: transparent;
  border-top-color: var(--lc-color-bg-2);
  border-width: 4px;
  margin-left: -4px;
}
.inline-toolbar:not(.no-arrow):before {
  bottom: 100%;
  border-color: transparent;
  border-bottom-color: var(--lc-color-bg-2);
  border-width: 4px;
  margin-left: -4px;
}
