.rich-text.rich-text-v2 {
  white-space: pre-wrap;
}
.rich-text.rich-text-v2 > p:not(:last-child),
.rich-text.rich-text-v2 > div:not(:last-child) {
  margin-bottom: 16px;
}
.rich-text.rich-text-v2 > p:first-child,
.rich-text.rich-text-v2 > div:first-child {
  margin-top: 8px;
}
.rich-text.rich-text-v2 > p:last-child,
.rich-text.rich-text-v2 > div:last-child {
  margin-bottom: 8px;
}
.rich-text.rich-text-v2 > figure,
.rich-text.rich-text-v2 > pre,
.rich-text.rich-text-v2 > .table-wrapper,
.rich-text.rich-text-v2 > code {
  margin: 20px 0;
}
.rich-text.rich-text-v2 > figure:first-child,
.rich-text.rich-text-v2 > pre:first-child,
.rich-text.rich-text-v2 > .table-wrapper:first-child,
.rich-text.rich-text-v2 > code:first-child {
  margin-top: 8px;
}
.rich-text.rich-text-v2 > figure:last-child,
.rich-text.rich-text-v2 > pre:last-child,
.rich-text.rich-text-v2 > .table-wrapper:last-child,
.rich-text.rich-text-v2 > code:last-child {
  margin-bottom: 8px;
}
.rich-text.rich-text-v2 > figure.editor-not-support-element,
.rich-text.rich-text-v2 > pre.editor-not-support-element,
.rich-text.rich-text-v2 > .table-wrapper.editor-not-support-element,
.rich-text.rich-text-v2 > code.editor-not-support-element {
  padding: 0.5rem 0.5rem;
  background-color: var(--yellow4);
}
.rich-text.rich-text-v2 > blockquote:first-child {
  margin-top: 8px;
}
.rich-text.rich-text-v2 > blockquote:last-child {
  margin-bottom: 8px;
}
.rich-text.rich-text-v2 > blockquote.single-blockquote {
  margin: 20px 0;
}
.rich-text.rich-text-v2 > blockquote.first-blockquote {
  margin-top: 20px;
}
.rich-text.rich-text-v2 > blockquote + blockquote:not(.middle-blockquote) {
  margin-bottom: 20px;
}
.rich-text.rich-text-v2 > h2 {
  margin-top: 36px;
  margin-bottom: 22px;
}
.rich-text.rich-text-v2 > h2:first-child {
  margin-top: 12px;
}
.rich-text.rich-text-v2 > h3 {
  margin-top: 28px;
  margin-bottom: 20px;
}
.rich-text.rich-text-v2 > h3:first-child {
  margin-top: 12px;
}
.rich-text.rich-text-v2 > h4,
.rich-text.rich-text-v2 h5,
.rich-text.rich-text-v2 h6 {
  margin-top: 24px;
  margin-bottom: 18px;
}
.rich-text.rich-text-v2 > h4:first-child,
.rich-text.rich-text-v2 h5:first-child,
.rich-text.rich-text-v2 h6:first-child {
  margin-top: 12px;
}
.rich-text.rich-text-v2 > ul,
.rich-text.rich-text-v2 > ol {
  margin-top: 18px;
  margin-bottom: 18px;
}
.rich-text.rich-text-v2 > ul:first-child,
.rich-text.rich-text-v2 > ol:first-child {
  margin-top: 8px;
}
.rich-text.rich-text-v2 > ul:last-child,
.rich-text.rich-text-v2 > ol:last-child {
  margin-bottom: 8px;
}
.rich-text.rich-text-v2 li {
  margin-top: 0;
}
.rich-text.rich-text-v2 li:not(:last-child) {
  margin-bottom: 12px;
}
.rich-text.rich-text-v2 li p:not(:last-child) {
  margin-bottom: 12px;
}
.rich-text.rich-text-v2 li ul:not(:first-child),
.rich-text.rich-text-v2 li ol:not(:first-child) {
  margin: 12px 0;
}
.rich-text.rich-text-v2 .nest-wrapper li {
  margin-left: 2em;
}
.rich-text {
  letter-spacing: 0.02em;
}
.rich-text .table-wrapper {
  width: 100%;
  overflow: auto;
}
.rich-text table td {
  border: 1px solid var(--gray5);
  min-width: 120px;
  max-width: 240px;
  min-height: 32px;
  padding: 7px 9px;
}
.rich-text .lc-source-code {
  padding: 1rem;
  overflow: auto;
  font-size: 0.9em;
  background-color: var(--gray4);
  border-radius: 4px;
}
.rich-text .lc-inline-code {
  display: inline;
  font-family: Menlo, Courier, monospace, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Segoe UI';
  font-size: 0.9em;
  padding: 2px 4px;
  margin: 0 4px;
  background-color: var(--gray4);
  border-radius: 4px;
}
.rich-text h1 {
  font-size: 2em;
  font-weight: 500;
  margin-bottom: 1rem;
}
.rich-text h2 {
  font-size: 1.5em;
  font-weight: 500;
  margin-bottom: 1rem;
}
.rich-text h3 {
  font-size: 1.25em;
  font-weight: 500;
  margin-bottom: 0.75rem;
}
.rich-text h4 {
  font-size: 1.1em;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.rich-text figure {
  margin: 0;
  max-width: 100%;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
}
.rich-text ul {
  margin: 4px 0;
  padding: 0;
  list-style: disc;
}
.rich-text ol {
  margin: 12px 0;
  padding: 0;
  list-style: decimal;
}
.rich-text li {
  margin: 4px 0;
  margin-left: 1.5em;
}
.rich-text p {
  word-break: break-word;
}
.rich-text p:not(:last-child) {
  margin-bottom: 6px;
}
.rich-text strong {
  font-weight: 500;
}
.rich-text pre {
  margin: 8px auto;
  background-color: rgba(27, 31, 35, 0.05);
}
.rich-text pre div,
.rich-text pre p {
  overflow: auto;
  white-space: nowrap;
  padding: 10px;
}
.rich-text blockquote {
  margin: 0;
  padding: 16px;
  border-left: 3px solid var(--gray7);
  background-color: var(--gray4);
}
.rich-text a {
  color: var(--blue10);
}
.rich-text hr {
  /* opacity: 0.4; */
  margin: 48px auto 56px;
  max-width: 15rem;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--gray6);
}
