.bw-confirm-dialog {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.bw-confirm-dialog .dialog-container {
  background-color: white;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 2001;
}
@media (min-width: 640px) {
  .bw-confirm-dialog .dialog-container {
    max-width: 25rem;
  }
}
@media (max-width: 639px) {
  .bw-confirm-dialog .dialog-container {
    max-width: 80%;
  }
}
.bw-confirm-dialog .dialog-container .dialog-head {
  padding: 1.5rem 2rem 0.5rem;
  font-weight: bold;
}
.bw-confirm-dialog .dialog-container .dialog-body {
  padding: 0.5rem 2rem 1.5rem;
  font-size: 14px;
}
.bw-confirm-dialog .dialog-container .dialog-footer {
  display: flex;
  flex-direction: row;
  font-size: 14px;
}
.bw-confirm-dialog .dialog-container .dialog-footer button {
  padding: 0.75rem;
  background-color: white;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bw-confirm-dialog .dialog-container .dialog-footer button.cancel {
  opacity: 0.7;
}
.bw-confirm-dialog .dialog-mask {
  background-color: rgba(39, 44, 54, 0.4);
}
.bw-confirm-dialog .dialog-mask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
